import React, { useState, useEffect } from "react";
import _, { debounce } from "lodash";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LandedCostSlabRight } from "../LandedCostSlabDrawer/style";
import { makeStyles } from "@mui/styles";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const drawerWidth = 350;

const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

export default function
  LandedCostSlabDrawer({
    isLandedCostSlabOpened,
    isLandedCostSlabClosed,
    data,
    //mainTemplateQuotation,
    slabMainTemplateQuotation,
    selectedCurrency,
    currencyDetails,
    transactionItemSequence,
    selectedQuotedItems,
    vendorType,
    TotalCostFIeld,
    EvalResultsKeyWiseSlab,
    currentTxn,
    from,
    RateField,
    PerCost,
    items,
  }) {
  const classes = useStyles();
  const [totalBasicValue, setTotalBasicValue] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [RateKeyWise, setRateKeyWise] = useState(0);
  const [QuantityKeyWise, setQuantityKeyWise] = useState(0);
  const [itemNames, setItemNames] = useState({});
  const [currencyIcon, setCurrencyIcon] = useState({});
  const [
    reorderedSlabMainTemplateQuotationItemSequence,
    setReorderedSlabMainTemplateQuotationItemSequence,
  ] = useState({});

  const [Toggle, setToggle] = useState({});

  // Extract item names from the API response
  useEffect(() => {
    if (data && data.items) {
      setItemNames(data.items);
    }
  }, [data]);


  const calculateTotals = () => {
    let basicValue = 0;
    let value = 0;
    let rateKeyWise = {};
    let quantityKeyWise = {};
    if (slabMainTemplateQuotation) {
      Object.entries(slabMainTemplateQuotation).forEach(
        ([key, item], index) => {
          const quantitySlab = selectedQuotedItems[key]?.quantity;
          const solutions = slabMainTemplateQuotation[key] || [];

          // Initialize an array to store the indices of filtered solutions
          let filteredSolutionIndices = [];

          // First, try to find an exact match
          let filteredSolutions = solutions.filter((solution, index) => {
            if (solution.select_slab === "Y") {
              const range = solution?._range;

              // Exact match logic for "More Than"
              if (range && range.includes("More Than")) {
                const min = parseInt(range.split(" ")[2], 10);
                if (quantitySlab === min) {
                  filteredSolutionIndices.push(index); // Store the index
                  return true; // Exact match for "More Than"
                }
              }
              // Exact match logic for range
              else if (range) {
                const [min, max] = range.split('-').map(Number);
                if (quantitySlab >= min && quantitySlab <= max) {
                  filteredSolutionIndices.push(index); // Store the index
                  return true; // Exact match for range
                }
              }
            }
            return false; // Default return false if no conditions are met
          });

          // If no exact match is found, check for the next nearest slab
          if (filteredSolutions.length === 0) {
            filteredSolutions = solutions.filter((solution, index) => {
              if (solution.select_slab === "Y") {
                const range = solution?._range;

                // Logic for the next nearest "More Than"
                if (range && range.includes("More Than")) {
                  const min = parseInt(range.split(" ")[2], 10);
                  if (quantitySlab < min) {
                    filteredSolutionIndices.push(index); // Store the index
                    return true; // Ensure the quantitySlab is less than min for "More Than"
                  }
                }
                // Logic for the next nearest range
                else if (range) {
                  const [min] = range.split('-').map(Number);
                  if (quantitySlab < min) {
                    filteredSolutionIndices.push(index); // Store the index
                    return true; // Ensure the quantitySlab is less than min for the selected ranges
                  }
                }
              }
              return false; // Default return false if no conditions are met
            });

            // Sort by ascending order to get the next available slab
            filteredSolutions = filteredSolutions.sort((a, b) => {
              const rangeA = a._range.includes("More Than")
                ? parseInt(a._range.split(" ")[2], 10)
                : parseInt(a._range.split('-')[0], 10);
              const rangeB = b._range.includes("More Than")
                ? parseInt(b._range.split(" ")[2], 10)
                : parseInt(b._range.split('-')[0], 10);
              return rangeA - rangeB; // Sort by ascending order
            });

            // Update the indices accordingly after sorting
            filteredSolutionIndices = filteredSolutionIndices.sort((a, b) => {
              const rangeA = solutions[a]._range.includes("More Than")
                ? parseInt(solutions[a]._range.split(" ")[2], 10)
                : parseInt(solutions[a]._range.split('-')[0], 10);
              const rangeB = solutions[b]._range.includes("More Than")
                ? parseInt(solutions[b]._range.split(" ")[2], 10)
                : parseInt(solutions[b]._range.split('-')[0], 10);
              return rangeA - rangeB;
            });

            // Select the first (smallest) valid slab that is greater than the quantitySlab
            if (filteredSolutions.length > 0) {
              filteredSolutions = filteredSolutions.slice(0, 1);
              filteredSolutionIndices = [filteredSolutionIndices[0]];
            }
          }

          // If still no solutions found, ensure to return the solution with select_slab "Y"
          if (filteredSolutions.length === 0) {
            // Try to find the largest available range
            filteredSolutions = solutions
              .filter((solution, index) => {
                if (solution.select_slab === "Y") {
                  filteredSolutionIndices.push(index); // Store the index
                  return true;
                }
                return false;
              })
              .sort((a, b) => {
                const rangeA = a._range.includes("More Than")
                  ? parseInt(a._range.split(" ")[2], 10)
                  : parseInt(a._range.split('-')[0], 10);
                const rangeB = b._range.includes("More Than")
                  ? parseInt(b._range.split(" ")[2], 10)
                  : parseInt(b._range.split('-')[0], 10);
                return rangeA - rangeB; // Sort by ascending order
              });

            // Select the last (largest) range
            filteredSolutions = filteredSolutions.slice(-1);
            filteredSolutionIndices = [filteredSolutionIndices.slice(-1)[0]];
          }

          // Handle case where filteredSolutions is undefined or empty
          const selectedSolution =
            filteredSolutions.length > 0 ? filteredSolutions[0] : solutions[0];
          const quantity = selectedSolution?.qty;
          let rate = selectedSolution?.rate;

          // Default to the first solution if no matching indices found
          let selectedIndex =
            filteredSolutionIndices.length > 0 ? filteredSolutionIndices[0] : 0;

          if (isNaN(rate)) {
            if (RateField && from === "View") {
              rate =
                currentTxn?.template?.itemize?.[key]?.solution[0].data[selectedIndex || 0][
                RateField.FieldId
                ];
            } else if (RateField && from !== "View") {
              rate = EvalResultsKeyWiseSlab[key][selectedIndex || 0][RateField.FieldId];
            }
          }
          rateKeyWise[key] = rate;
          quantityKeyWise[key] = quantity;

          // Convert undefined, null, or NaN to zero
          const validQuantity = isNaN(quantity) ? 0 : quantity;
          const validRate = isNaN(rate) ? 0 : rate;

          const itemValue = validQuantity * validRate;

          let totalValue = 0;
          if (from === "View") {
            if (PerCost) {
              totalValue =
                (currentTxn?.template?.itemize?.[key]?.solution[0].data[selectedIndex || 0]?.[
                  TotalCostFIeld.FieldId
                ] /
                  (slabMainTemplateQuotation[key][selectedIndex || 0][
                    PerCost.FieldId
                  ]["value"] || 0)) *
                validQuantity;
            } else {
              totalValue =
                currentTxn?.template?.itemize?.[key]?.solution[0].data[selectedIndex || 0]?.[
                TotalCostFIeld.FieldId
                ] * validQuantity;
            }
          } else {
            if (PerCost) {
              totalValue =
                (EvalResultsKeyWiseSlab[key][selectedIndex || 0][
                  TotalCostFIeld?.FieldId
                ] /
                  (slabMainTemplateQuotation[key][selectedIndex || 0][
                    PerCost.FieldId
                  ]["value"] || 0)) *
                validQuantity;
            } else {

              if (selectedIndex == undefined) {
                selectedIndex = 0;
              }
              totalValue = EvalResultsKeyWiseSlab[key][selectedIndex][TotalCostFIeld.FieldId] * validQuantity;
            }
          }
          basicValue += itemValue;
          if (totalValue) {
            value += parseFloat(totalValue);
          }
        }
      );
      const slabMainTemplateQuotationMap = new Map(
        Object.entries(slabMainTemplateQuotation).map((item) => [
          item[0],
          item[1],
        ])
      );
      let reorderedItemSequence = transactionItemSequence
        .filter((key) => slabMainTemplateQuotationMap.has(key)) // Filter keys that are present in map
        .map((key) => key);
      setReorderedSlabMainTemplateQuotationItemSequence(reorderedItemSequence);
    }
    setTotalBasicValue(basicValue.toFixed(3));
    setTotalValue(value.toFixed(3));
    setRateKeyWise(rateKeyWise);
    setQuantityKeyWise(quantityKeyWise);
  };

  useEffect(() => {
    return () => {
      const resizeObserver = document.querySelector(".ResizeObserver");
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  // Call calculateTotals whenever the drawer is opened or the data changes
  useEffect(() => {
    if (isLandedCostSlabOpened?.right) {
      calculateTotals();
    }
  }, [isLandedCostSlabOpened, data, slabMainTemplateQuotation]);

  useEffect(() => {
    setCurrencyIcon(
      // getSymbol(selectedCurrency ? selectedCurrency : currencyDetails)
      getSymbol(selectedCurrency)
    );
  }, [selectedCurrency, currencyDetails]);
  const subRows = (key) => {
    setToggle((prevState) => ({
      ...prevState,
      [key]: true,
    }));
  };

  const removeSubRow = (key) => {
    setToggle((prevState) => ({
      ...prevState,
      [key]: false,
    }));
  };
  return (
    <LandedCostSlabRight
      anchor="right"
      open={isLandedCostSlabOpened?.right}
      className="LandedCostRFQ-Sidebar"
    // sx={{zIndex:'1301'}}
    >
      <Card variant="outlined">
        <CardHeader
          title={
            <Typography
              variant="h6"
              fontSize="18px"
              fontWeight={500}
              color={"secondary.main"}
            >
              Total Cost Details
            </Typography>
          }
          action={
            <Tooltip title="Close">
              <IconButton
                onClick={isLandedCostSlabClosed}
                aria-label="close"
                size="small"
                sx={{ color: "var(--grey-500)" }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          }
          sx={{
            "& .MuiCardHeader-action": {
              alignSelf: "center",
            },
            "& .MuiCardHeader-avatar": {
              mr: 1,
            },
          }}
        />
        <Divider />
        <CardContent sx={{ p: 0 }}>
          <Box
            sx={{
              height: "calc(100vh - 62px)",
              width: "100%",
              overflowX: "auto",
              overflowY: "auto",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={40}>&nbsp;</TableCell>
                  <TableCell width={200}>Item Name</TableCell>
                  <TableCell align="right" width={130}>
                    Quantity
                  </TableCell>
                  <TableCell align="right" width={130}>
                    Rate {currencyIcon && `(${currencyIcon})`}
                  </TableCell>
                  <TableCell align="right" width={200}>
                    Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {Object.entries(slabMainTemplateQuotation || {}).map( */}
                {Object.entries(
                  reorderedSlabMainTemplateQuotationItemSequence || {}
                ).map(([item, itemId]) => {
                  const itemName = data[itemId]?.name || "Unknown";
                  if (data[itemId]) {
                    const rate = parseFloat(RateKeyWise[itemId]);
                    const qty = parseFloat(QuantityKeyWise[itemId]);
                    // Convert undefined, null, or NaN to zero
                    const validRate = isNaN(rate) ? 0 : rate;
                    const validQty = isNaN(qty) ? 0 : qty;

                    const subtotal = validRate * validQty;
                    return (
                      <>
                        <TableRow key={itemId} className="ItemDetls-Row">
                          <TableCell width={40}>
                            {Object.keys(slabMainTemplateQuotation).length >
                              0 &&
                              slabMainTemplateQuotation?.[itemId]?.length > 1 &&
                              (!Toggle[itemId] ? (
                                <IconButton
                                  size="small"
                                  onClick={() => subRows(itemId)}
                                >
                                  <AddIcon fontSize="inherit" />
                                </IconButton>
                              ) : (
                                <IconButton
                                  size="small"
                                  onClick={() => removeSubRow(itemId)}
                                >
                                  <RemoveIcon fontSize="inherit" />
                                </IconButton>
                              ))}
                          </TableCell>
                          <TableCell width={200}>
                            <Tooltip
                              title={_.unescape(itemName)}
                              placement="bottom"
                            >
                              <Typography
                                variant="body2"
                                fontWeight={500}
                                sx={{ wordBreak: "break-word" }}
                              >
                                {_.unescape(itemName)}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="right" width={130}>
                            <Typography variant="body2" noWrap>
                              {parseFloat(validQty).toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })}
                            </Typography>
                          </TableCell>
                          <TableCell align="right" width={130}>
                            <Typography variant="body2" noWrap>
                              {/* {currencyIcon} {validRate.toFixed(3)} */}
                              {currencyIcon}{" "}
                              {parseFloat(validRate).toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })}
                            </Typography>
                          </TableCell>
                          <TableCell align="right" width={200}>
                            <Typography variant="body2" noWrap>
                              {currencyIcon}{" "}
                              {parseFloat(subtotal).toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {Toggle[itemId] && (
                          <TableRow
                            sx={{
                              "& .MuiTableCell-root": {
                                background: "var(--grey-100) !important"
                              }
                            }}
                          >
                            <TableCell width={40}>&nbsp;</TableCell>
                            <TableCell colSpan={4}>
                              <Typography variant="body2" fontWeight={500}>
                                SLAB RANGE
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                        {Toggle[itemId] &&
                          slabMainTemplateQuotation[itemId].map(
                            (item, index) => {
                              if (item?.select_slab == "Y") {
                                const rateSlab = parseFloat(item?.rate);
                                const qtySlab = parseFloat(item?.qty);
                                let rate = 0;

                                if (from === "View") {
                                  rate =
                                    currentTxn?.template?.itemize?.[itemId]?.solution[0].data[index][
                                    RateField?.FieldId
                                    ];
                                } else {
                                  rate = EvalResultsKeyWiseSlab[itemId][index]?.[RateField?.FieldId];
                                }
                                // Convert undefined, null, or NaN to zero
                                const validRateSlab = isNaN(rateSlab)
                                  ? isNaN(rate) ? 0 : rate
                                  : rateSlab;
                                const validQtySlab = isNaN(qtySlab)
                                  ? 0
                                  : qtySlab;
                                return (
                                  <TableRow
                                    sx={{
                                      "& .MuiTableCell-root": {
                                        background: "var(--grey-50) !important"
                                      }
                                    }}
                                  >
                                    <TableCell width={40}>&nbsp;</TableCell>
                                    <TableCell width={200}>
                                      <Tooltip
                                        title={_.unescape(item._range)}
                                        placement="bottom"
                                      >
                                        <Typography
                                          variant="body2"
                                          sx={{ wordBreak: "break-word" }}
                                        >
                                          {_.unescape(item._range)}
                                        </Typography>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell align="right" width={130}>
                                      <Typography variant="body2" noWrap>
                                        {parseFloat(
                                          validQtySlab
                                        ).toLocaleString("en-IN", {
                                          minimumFractionDigits: 3,
                                          maximumFractionDigits: 3,
                                        })}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="right" width={130}>
                                      <Typography variant="body2" noWrap>
                                        {currencyIcon}{" "}
                                        {parseFloat(
                                          validRateSlab
                                        ).toLocaleString("en-IN", {
                                          minimumFractionDigits: 3,
                                          maximumFractionDigits: 3,
                                        })}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="right" width={200}>
                                      <Typography variant="body2" noWrap>
                                        {currencyIcon}{" "}
                                        {parseFloat(
                                          validRateSlab * validQtySlab
                                        ).toLocaleString("en-IN", {
                                          minimumFractionDigits: 3,
                                          maximumFractionDigits: 3,
                                        })}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            }
                          )}
                      </>
                    );
                  }
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell align="right" colSpan={4}>
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="grey.500"
                    >
                      {vendorType === "Local"
                        ? "Total Basic Value"
                        : "Total Value"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="text.primary"
                      noWrap
                    >
                      {currencyIcon}{" "}
                      {parseFloat(totalBasicValue).toLocaleString("en-IN", {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      })}
                    </Typography>
                  </TableCell>
                </TableRow>

                {vendorType === "Local" && (
                  <TableRow>
                    <TableCell align="right" colSpan={4}>
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color="grey.500"
                        noWrap
                      >
                        Total Value
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color="text.primary"
                        noWrap
                      >
                        {currencyIcon}{" "}
                        {parseFloat(totalValue).toLocaleString("en-IN", {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3,
                        })}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableFooter>
            </Table>
          </Box>
        </CardContent>
      </Card>
    </LandedCostSlabRight>
  );
}
