import axios from "axios";
import React, { Component } from "react";
import {
  Select,
  FormControl,
  MenuItem,
  Button,
  Checkbox,
  Typography,
  TextField,
  Tooltip,
  InputAdornment,
  Link,
  FormControlLabel,
  Box,
  Badge,
  FormGroup,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  getArtworkDescription,
  getMaterialSpecificationFile,
  get_material_specifications,
  getmatspecghclfile,
} from "../../config/apiUrl";
import "../../pages/style.css";
import { openSnackbar } from "../../redux/actions/snackbarAction";
import EstimatedValue from "../Dialogs/EstimatedValue";
import moment from "moment";
import { ErrorOutline, Update } from "@mui/icons-material";
import { OutlineTextfieldLabel } from "../TextField";
import { connect } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { getSymbol } from "../AccountTemplate/CurrencySymbol";
import DeliveryScheduleDetailsView from "../Dialogs/DeliveryScheduleDetailsView";
import dayjs from "dayjs";
import MultiplantQty from "./MultiplantQty/MultiplantQty";
//import prconfig from "../../config/prconfig"
class QuotationFormFieldView extends Component {
  state = {
    deliveryScheduleOpen: false,
    estimatedValueOpen: false,
    value: "",
    checkDisabled: false,
    count: 0,
    selectedCurrency: "",
    multiplantQtyOpen: false,
    keys: "",
  };
  componentDidMount() {
    this.setState({
      value: this?.props?.FieldState?.value,
      checkDisabled: this?.props?.disabled,
      selectedCurrency: this?.props?.selectedCurrency,
      keys: this?.props?.keys,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.props.FieldState?.value !== prevProps.FieldState?.value) {
    if (
      this.props.FieldState !== prevProps.FieldState ||
      this.props.selectedCurrency !== prevProps.selectedCurrency ||
      this.props.disabled !== prevProps.disabled ||
      this.props.keys !== prevProps.keys
    ) {
      this.setState({
        // value: this.props.FieldState?.value,
        value: this.props.FieldState?.value,
        checkDisabled: this?.props?.disabled,
        selectedCurrency: this?.props?.selectedCurrency,
        keys: this?.props?.keys,
      });
    }
  }

  setValue = (evt) => {
    this.setState({
      value: evt.target.value,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.value !== nextState.value) {
      return true;
    }
    if (this.state.estimatedValueOpen !== nextState.estimatedValueOpen) {
      return true;
    }
    if (this.state.deliveryScheduleOpen !== nextState.deliveryScheduleOpen) {
      return true;
    }
    if (this.props.FieldState !== nextProps.FieldState) {
      return true;
    }
    if (this.props.FieldState?.error !== nextProps.FieldState?.error) {
      return true;
    }
    if (this.props.FieldState?.options !== nextProps.FieldState?.options) {
      return true;
    }
    if (this.props.upd_budget_master !== nextProps.upd_budget_master) {
      return true;
    }
    if (this.props.qty !== nextProps.qty) {
      return true;
    }
    if (this.props.rate !== nextProps.rate) {
      return true;
    }
    if (this.state.selectedCurrency !== nextProps.selectedCurrency) {
      return true;
    }
    if (this.state.checkDisabled !== nextProps.checkDisabled) {
      return true;
    }
    if (this.state.keys !== nextProps.keys) {
      return true;
    }
    return false;
  }

  handleMaterialSpecificationClick = () => {
    if (this.props.destination) {
      axios
        .get(
          getMaterialSpecificationFile(
            this.props.item.new_item_id,
            this.props.destination
          )
        )
        .then((response) => {
          if (response.data.path) {
            let a = document.createElement("a");
            a.href = response.data.path;
            a.download = "MaterialSpecificationFile";
            a.target = "_blank";
            a.rel = "noopener noreferrer";
            a.click();
          }
        });
    } else {
      this.props.openSnackbar("error", "please select Destination first!");
    }
  };

  handlematspecghclfileClick = () => {
    //this is the palce for mat description
    let pr_dest_code = "";
    // if (this.props.prlineitems[this.props.item.id]?.length > 0) {
    //   pr_dest_code = this.props.LATEST("Destination", this.props.item);
    // }
    axios
      .get(getmatspecghclfile(this.props.inquiryId, this.props.item.code, "Inquiry", pr_dest_code))
      .then((response) => {
        if (response.data.path) {
          let a = document.createElement("a");
          a.href = response.data.path;
          a.download = "Matspecghclfile";
          a.target = "_blank";
          a.rel = "noopener noreferrer";
          a.click();
        }
      });
  };

  openLink() {
    const link =
      "/cgi-bin/vendx/index.cgi?vendx_action=get_material_specifications&UID=53616c7465645f5f8d8b8aeb500390a0bce740eebae49d0ddc11e83959f70f6d24767952a1b54258";
    window.open(link, "_blank");
  }

  getMaterialSpecifications = (uid) => {
    const form = document.createElement("form");
    form.target = "_blank";
    form.action = get_material_specifications + uid;
    form.method = "POST"; // or "GET" if needed

    const hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = "UID";
    hiddenInput.value = uid;

    form.appendChild(hiddenInput);
    document.body.appendChild(form);
    form.submit();
  };

  ArtworkDescription = () => {
    // let batch_no = this.props.batch_id;
    // if (this.props.prlineitems[this.props.item.id]?.length > 0) {
    //   batch_no = this.props.LATEST("PRBatchNo", this.props.item);
    // }
    // changes made after confirming with devarshi mam for CI7-T327
    let batch_no = '';

    let batch_no_data =
      Object.values(
        this.props.mainTemplateQuotation[this.props.keys]).find(
          (obj) =>
            obj.Identifier === "PRBatchNo" ||
            obj.Identifier === "PRBatchNoForeign"
        );

    batch_no = batch_no_data.value !== '' ? batch_no_data.value : batch_no_data.DefaultValue;

    axios
      .get(getArtworkDescription(this.props.item.code, (batch_no !== '' ? batch_no : '')))
      .then((response) => {
        if (response.data.path) {
          let a = document.createElement("a");
          a.href = response.data.path;
          a.download = "ArtworkDescription";
          a.target = "_blank";
          a.rel = "noopener noreferrer";
          a.click();
        }
      });
  };
  handleClose = () => {
    this.setState({ multiplantQtyOpen: false });
  };

  render() {
    let {
      item,
      cell,
      qty,
      rate,
      base_currency,
      readOnly,
      SlabTableToggle,
      // selectedCurrency,
    } = this.props;
    let unit;
    let currency;

    if (this.state.multiplantQtyOpen) {
      return (
        <MultiplantQty
          open={this.state.multiplantQtyOpen}
          close={this.handleClose}
          itemData={item.plant_qty}
          Unit={item.unit}
          item={item}
        />
      );
    }
    if (cell?.UnitsFlag == 1) {
      unit = item?.unit;
    }
    if (cell?.UnitsFlag == 3) {
      unit = "Pack";
    }
    if (
      (cell?.CurrencyFormula === "BuyerLocalCurrency" &&
        cell?.Identifier === "Rate") ||
      cell?.CurrencyFormula === "SelectedCurrency"
    ) {
      currency =
        this.state.selectedCurrency === null ||
          this.state.selectedCurrency === undefined ||
          this.state.selectedCurrency === ""
          ? base_currency
          : this.props.selectedCurrency; //`/${item?.unit}`;
      if (unit) unit = `${unit}`;
    } else if (cell?.CurrencyFormula == "BuyerLocalCurrency") {
      currency = base_currency;
    } else if (
      cell?.CurrencyFormula != "" &&
      cell?.CurrencyFormula.split("||") &&
      cell?.CurrencyFormula.split("||")[0].split(".") &&
      cell.CurrencyFlag != 0
    ) {
      currency = this.props.selectedCurrency;
      if (unit) unit = `${unit}`;
    }

    let matserCheckbox =
      cell.Identifier === "EstimatedCost" ||
      cell.Identifier === "EstimatedCostForeign" ||
      cell.Identifier === "Budgetedrate" ||
      cell.Identifier === "BudgetedrateForeign";
    let error, ErrorMessage, name, value, DynamicDefaultValue;
    if (this.props.FieldState) {
      error = this.props.FieldState?.error;
      ErrorMessage = this.props.FieldState?.ErrorMessage;
      // name = this.props.FieldState?.HTMLAttributeName;
      name = this.props.FieldState.name;
      value = this.props.FieldState.value;
      DynamicDefaultValue = this.props.FieldState?.DynamicDefaultValue;
    }
    // if (cell.Identifier === "EstimatedValue") {
    //   return (
    //     <React.Fragment>
    //       <Button
    //         color="primary"
    //         aria-controls="fade-menu"
    //         aria-haspopup="true"
    //         disabled={SlabTableToggle}
    //         onClick={() => {
    //           this.setState({
    //             estimatedValueOpen: true,
    //           });
    //         }}
    //         variant="contained"
    //       >
    //         Details
    //       </Button>
    //       <EstimatedValue
    //         open={this.state.estimatedValueOpen}
    //         qty={qty}
    //         rate={rate}
    //         unit={item?.unit}
    //         currency={currency}
    //         handleClose={() => {
    //           this.setState({
    //             estimatedValueOpen: false,
    //           });
    //         }}
    //       />
    //     </React.Fragment>
    //   );
    // } else
    if (
      cell.Identifier.includes("DeliverySchedule") &&
      cell.ElementType === "TA"
    ) {
      let value = "";
      if (this.props.FieldState) {
        value = this.props.FieldState["value"];
        if (value) {
          value = value.split(";").length;
        } else {
          value = "";
        }
      }
      return (
        <React.Fragment>
          {value ? (
            <Badge color="primary" badgeContent={value}>
              <Button
                startIcon={<CalendarTodayIcon />}
                onClick={() => {
                  this.setState({
                    deliveryScheduleOpen: true,
                  });
                }}
                variant="text"
              >
                View Schedule
                {error ? (
                  <Tooltip title={ErrorMessage}>
                    <ErrorOutline sx={{ color: "error.main", ml: 1 }} />
                  </Tooltip>
                ) : null}
              </Button>
            </Badge>
          ) : (
            <Button
              startIcon={<CalendarTodayIcon />}
              onClick={() => {
                this.setState({
                  deliveryScheduleOpen: true,
                });
              }}
              variant="text"
            >
              View Schedule
              {error ? (
                <Tooltip title={ErrorMessage}>
                  <ErrorOutline sx={{ color: "error.main", ml: 1 }} />
                </Tooltip>
              ) : null}
            </Button>
          )}
          {this.state.deliveryScheduleOpen && (
            <DeliveryScheduleDetailsView
              open={this.state.deliveryScheduleOpen}
              unit={item?.unit}
              handleClose={() => {
                this.setState({
                  deliveryScheduleOpen: false,
                });
              }}
              updateFieldValue={(newValue) => {
                this.props.updateDeliveryScheduleFieldValue(
                  newValue,
                  this.props.keys,
                  cell.FieldId
                );
              }}
              value={this.props.FieldState && this.props.FieldState["value"]}
              readonly={readOnly}
            />
          )}
        </React.Fragment>
      );
    } else if (cell.ElementType === "S") {
      if (
        this.props.multiplePlantsFlag &&
        cell.Identifier === "GSTType" &&
        cell.FieldLabel === "GST Transition Type *"
      ) {
        return null;
      }
      return (
        <Tooltip
          title={
            this.props?.diffrentiatedFields &&
              this.props?.diffrentiatedFields.hasOwnProperty(cell.FieldId) &&
              (value || this.state.value)
              ? this.props.diffrentiatedFields[cell.FieldId]
                ? `Previous Value : ${this.props.diffrentiatedFields[cell.FieldId]
                }`
                : "Previous Value : N/A"
              : value && value.split("-")[0] == value.split("-")[1]
                ? value.split("-")[0]
                : value
          }
          placement="bottom-start"
          disableInteractive
        >
          <Typography
            color={
              this.props?.diffrentiatedFields &&
                this.props?.diffrentiatedFields?.hasOwnProperty(cell.FieldId) &&
                (value || this.state.value)
                ? "error.main"
                : ""
            }
            noWrap
            variant="body2"
            sx={{ maxWidth: "160px" }}
          >
            {value
              ? value.split("-")[0] == value.split("-")[1]
                ? value.split("-")[0]
                : value
              : ""}
          </Typography>
        </Tooltip>
      );
    } else if (cell.Identifier === "ArtworkDescription") {
      return (
        <Button
          component="Text"
          color="primary"
          onClick={() => this.ArtworkDescription()}
        >
          {cell.FieldLabel}
        </Button>
      );
    } else if (
      cell.Identifier === "BasicHistoricalPrice" ||
      cell.Identifier === "LandedHistoricalPrice" ||
      cell.Identifier === "DateHP"
    ) {
      return <Typography>{DynamicDefaultValue}</Typography>;
    } else if (cell.Identifier === "MaterialSpecificationFile") {
      return (
        <Button
          component="Text"
          color="primary"
          onClick={() => this.handleMaterialSpecificationClick()}
        >
          {cell.FieldLabel}
        </Button>
      );
    } else if (cell.Identifier === "matspecghclfile") {
      return (
        <Button
          component="text"
          color="primary"
          onClick={() => this.handlematspecghclfileClick()}>
          {cell.FieldLabel}
        </Button>
      );
    } else if (cell.Identifier === "ItemMasterSPEC") {
      return (
        <React.Fragment>
          <Tooltip
            title={item?.specifications}
            placement="bottom-start"
            disableInteractive
          >
            <Typography noWrap sx={{ maxWidth: "160px" }}>
              {item?.specifications}
            </Typography>
          </Tooltip>
        </React.Fragment>
      );
    }
    // else if (cell.Identifier === "MasterHSNCode") {
    //   return (
    //     <React.Fragment>
    //       <Typography>{item?.hsn_code}</Typography>
    //     </React.Fragment>
    //   );
    // }
    else if (
      cell.DefaultValue &&
      cell.DefaultValue.Button === "Y" &&
      cell.ElementType === "D"
    ) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "right",
          }}
        >
          <Tooltip
            title={
              this.props.selectedCurrency && (
                this.props.multipleSolutionFlag && this.props.slabFlag
                  ? getSymbol(this.props.selectedCurrency)
                    ? `${getSymbol(this.props.selectedCurrency)} ${parseFloat(this.props.CurrentTransaction.solution[0].data[this.props.solutionRowIndex || 0][cell.FieldId.toString()] ?? "").toFixed(3)} / ${unit ? unit : ""}`
                    : ""
                  : getSymbol(this.props.selectedCurrency)
                    ? `${getSymbol(this.props.selectedCurrency)} ${parseFloat(this.props.CurrentTransaction.solution[0].data[0][cell.FieldId.toString()] ?? "").toFixed(3)} / ${unit ? unit : ""}`
                    : ""
              )
            }

            disableInteractive
            placement="bottom-start"
          >
            <Typography
              variant="body2"
              textAlign="right"
              noWrap
              sx={{
                maxWidth: "120px",
                minWidth: "120px",
                // minHeight: "36px",
                // height: "36px",
                // whiteSpace: "nowrap",
                // overflow: "hidden",
                // textOverflow: "ellipsis",
                // padding: "8.5px 8.5px",
              }}
            >

              {this.props.selectedCurrency &&
                getSymbol(this.props.selectedCurrency)
                ? getSymbol(this.props.selectedCurrency)
                : ""
            }
            {this.props.multipleSolutionFlag && this.props.slabFlag
              ? `${parseFloat(this.props.CurrentTransaction?.solution[0]?.data?.[this.props.solutionRowIndex || 0]?.[cell.FieldId.toString()] ?? "").toFixed(3)}`
              : `${parseFloat(this.props.CurrentTransaction?.solution?.[this.props.solutionIndex]?.data?.[0]?.[cell.FieldId.toString()] ?? "").toFixed(3)}`
            }
              /{unit ? unit : ""}
            </Typography>
          </Tooltip>
          <Button
            variant="text"
            size="small"
            onClick={() => {
              // this.props.multipleSolutionFlag && this.props.slabFlag && this.props.CurrentTransaction.solution[0].data[this.props.solutionRowIndex] !== undefined ?
              this.props.multipleSolutionFlag && this.props.slabFlag ?
                this.props.handleDetailsClickForSlab(
                  this.props.keys,
                  this.props.solutionRowIndex || 0,
                  cell.Identifier,
                  cell.FieldLabel,
                  cell.UnitsFlag
                ) : (
                  this.props.handleDetailsClick(
                    this.props.keys,
                    this.props.solutionIndex,
                    cell.Identifier,
                    cell.FieldLabel,
                    cell.UnitsFlag
                  )
                )
            }}
            sx={{ backgroundColor: "var(--primary-50)" }}
          >
            Details
          </Button>
        </Box>
      );
    } else if (cell?.ElementType === "TB") {
      if (this.state.value) {
        return (
          <React.Fragment>
            <Tooltip
              title={
                this.props?.diffrentiatedFields &&
                  this.props?.diffrentiatedFields.hasOwnProperty(cell.FieldId) &&
                  this.state.value
                  ? this.props.diffrentiatedFields[cell.FieldId]
                    ? `Previous Value : ${`${currency ? getSymbol(currency) : ""
                    } ${this.props.diffrentiatedFields[cell.FieldId]} / ${unit ? unit : ""
                    }`}`
                    : "Previous Value : N/A"
                  : ` ${currency ? getSymbol(currency) : ""} ${this.state.value
                  }  ${unit ? `/ ${unit}` : ""}`
              }
              disableInteractive
              placement="bottom-start"
            >
              <Typography
                variant="body2"
                noWrap
                color={
                  this.props?.diffrentiatedFields &&
                    this.props?.diffrentiatedFields.hasOwnProperty(
                      cell.FieldId
                    ) &&
                    this.state.value
                    ? "error.main"
                    : ""
                }
                sx={{
                  maxWidth: "160px",
                  minWidth: "160px",
                  // maxWidth: "240px",
                  // minWidth: "240px",
                  // minHeight: "36px",
                  // height: "36px",
                  // whiteSpace: "nowrap",
                  // overflow: "hidden",
                  // textOverflow: "ellipsis",
                  // padding: "8.5px 8.5px",
                }}
              >
                {currency && getSymbol(currency) ? getSymbol(currency) : ""}
                {` ${this.state.value} `} {unit ? `/ ${unit}` : ""}
              </Typography>
            </Tooltip>
          </React.Fragment>
        );
      }
    } else if (
      (cell.Identifier === "ProductQuantity") &&
      item?.plant_qty.length > 0
    ) {
      return (
        <>
          <FormGroup
            sx={{
              display: "inline-flex",
              "& .MuiTypography-root": {
                color: "var(--primary)",
                textDecoration: "underline",
                fontWeight: "500",
              },
            }}
          >
            {/* <FormControlLabel
          control={""}
          label={ */}
            <Typography
              variant="subtitle1"
              component="a"
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  multiplantQtyOpen: true,
                });
              }}
            >
              {this.state.value}
            </Typography>
            {/* } */}
            {/* /> */}
          </FormGroup>
          <span style={{ marginLeft: "4px" }}>{unit ? unit : ""}</span>
        </>
      );
    } else if (
      cell.Identifier === "ProductQuantity" ||
      cell.Identifier === "PER"
    ) {
      return (
        <React.Fragment>
          <Tooltip
            title={
              this.props?.diffrentiatedFields &&
                this.props?.diffrentiatedFields.hasOwnProperty(cell.FieldId) &&
                this.state.value
                ? this.props.diffrentiatedFields[cell.FieldId]
                  ? `Previous Value : ${this.props.diffrentiatedFields[cell.FieldId]
                  } ${unit ? unit : ""}`
                  : "Previous Value : N/A"
                : `${this.state.value} ${unit ? unit : ""}`
            }
            // title={this.props?.diffrentiatedFields.hasOwnProperty(cell.FieldId) && this.state.value ?  (this.props.diffrentiatedFields[cell.FieldId] ? `Previous Value : ${this.props.diffrentiatedFields[cell.FieldId]}` : "Previous Value : N/A")  : this.state.value}
            placement="bottom-start"
            disableInteractive
          >
            <Typography
              color={
                this.props?.diffrentiatedFields &&
                  this.props?.diffrentiatedFields.hasOwnProperty(cell.FieldId) &&
                  this.state.value
                  ? "error.main"
                  : ""
              }
              noWrap
              variant="body2"
              sx={{ maxWidth: "160px" }}
            >
              {/* {this.props.selectedCurrency &&
            getSymbol(this.props.selectedCurrency)
              ? getSymbol(this.props.selectedCurrency)
              : ""} */}
              {this.state.value} {unit ? unit : ""}
            </Typography>
          </Tooltip>
        </React.Fragment>
      );
    } else if (cell.ElementType === "M") {
      return (
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={!!value}
              name={name}
              onChange={(e) => {
                this.props.handleChange(
                  e,
                  cell.FieldId,
                  cell.FieldLabel,
                  cell.ElementType
                );
              }}
            />
          }
          label={cell.FieldLabel}
        />
      );
    } else if (cell.ElementType === "DT") {
      return (
        <Tooltip
          // title={
          //   this.props?.diffrentiatedFields &&
          //     this.props?.diffrentiatedFields.hasOwnProperty(cell.FieldId) &&
          //     (value || this.state.value)
          //     ? this.props.diffrentiatedFields[cell.FieldId]
          //       ? `Previous Value : ${dayjs(this.props.diffrentiatedFields[cell.FieldId], "YYYY-MM-DD").format(
          //         `DD/MM/YYYY [IST]`
          //       )
          //       }`
          //       : "Previous Value : N/A"
          //     : dayjs(value, "YYYY-MM-DD").format(
          //       `DD/MM/YYYY  [IST]`
          //     )
          // }
          title={
            this.props?.diffrentiatedFields &&
              this.props?.diffrentiatedFields.hasOwnProperty(cell.FieldId) &&
              (value != "" || this.state.value != "")
              ? this.props.diffrentiatedFields[cell.FieldId]
                ? `Previous Value : ${dayjs(
                  this.props.diffrentiatedFields[cell.FieldId],
                  "YYYY-MM-DD"
                ).format(`DD/MM/YYYY [IST]`)}`
                : "Previous Value : N/A"
              : value != "" || this.state.value != ""
                ? dayjs(value, "YYYY-MM-DD").format(`DD/MM/YYYY  [IST]`)
                : ""
          }
          placement="bottom-start"
          disableInteractive
        >
          <Typography
            color={
              this.props?.diffrentiatedFields &&
                this.props?.diffrentiatedFields?.hasOwnProperty(cell.FieldId) &&
                (value || this.state.value)
                ? "error.main"
                : ""
            }
            noWrap
            sx={{ maxWidth: "160px" }}
          >
            {/* {dayjs(value, "YYYY-MM-DD").format(`DD/MM/YYYY [IST]`)} */}
            {value != "" || this.state.value != ""
              ? dayjs(value, "YYYY-MM-DD").format(`DD/MM/YYYY  [IST]`)
              : ""}
          </Typography>
        </Tooltip>
      );
    } else if (cell.ElementType === "F") {
      return (
        <Badge
          color="error"
          badgeContent={this.state.value?.length}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={this.state.checkDisabled || this.state.readOnlyForSwitch}

            onClick={() => {
              this.props.handleUploadFiles(
                cell.FieldId,
                cell.Identifier,
                this.props.keys,
                this.props.rowIndex
              );
            }}
            startIcon={
              <VisibilityIcon sx={{ fontSize: "22px !important" }} />
            }
          >
            View Attachment
          </Button>
        </Badge>
      );
    } else if (cell.ElementType === "L") {
      return (
        <Link
          component="button"
          variant="body2"
          target="_blank"
          rel="noopener noreferrer"
        >
          {cell.FieldLabel}
        </Link>
      );
    } else {
      return (
        <Tooltip
          title={
            this.props?.diffrentiatedFields &&
              this.props?.diffrentiatedFields.hasOwnProperty(cell.FieldId) &&
              (value || this.state.value)
              ? this.props.diffrentiatedFields[cell.FieldId]
                ? `Previous Value : ${this.props.diffrentiatedFields[cell.FieldId]
                }`
                : "Previous Value : N/A"
              : value || this.state.value
          }
          placement="bottom-start"
          disableInteractive
        >
          <Typography
            color={
              this.props?.diffrentiatedFields &&
                this.props?.diffrentiatedFields?.hasOwnProperty(cell.FieldId) &&
                (value || this.state.value)
                ? "error.main"
                : ""
            }
            noWrap
            variant="body2"
            sx={{ maxWidth: "160px" }}
          >
            {value || this.state.value}
          </Typography>
        </Tooltip>
      );
      // return cell.FieldLabel;
    }
  }
}
const mapStateToProps = (state) => ({
  prlineitems: state.prrfqreducer.setPrLineItemsData,
});
export default connect(mapStateToProps, {
  openSnackbar,
})(QuotationFormFieldView);
