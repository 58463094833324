import React, { useEffect, useState } from "react";
import {
  IconButton,
  Typography,
  Box,
  Divider,
  FormControlLabel,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  List,
  ListItem,
  CardHeader,
  Card,
  Tooltip,
  CardContent,
  Button,
  Checkbox,
  Snackbar,
} from "@mui/material";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { Close as CloseIcon, ErrorOutline } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { QuatationDestinationDrawerRight } from "./style";
import {
  CustomAlert,
  CustomSnackbar,
} from "../../../components/Snackbar/style";

const useStyles = makeStyles(() => ({
  DestViewPlant: {
    "& .PlantHead": {
      background: "var(--grey-100)",
      borderBottom: "1px solid var(--bluegrey-500)",
      padding: "2px 16px",
      height:"32px",
      "& .MuiButtonBase-root": {
        padding: "2px",
        marginRight: "2px",
      },
      "& .MuiFormControlLabel-label": {
        fontSize: "14px",
        fontWeight: "500",
      },
      "& .MuiFormControlLabel-asterisk": {
        color: "var(--error-900)",
      },
    },
  },
}));

const DestinationDetailsDialogView = ({
  open,
  handleSave,
  itemizeData,
  setIsDestinationDetailsDialogOpen,
  lastTransactionInThreadCommon,
  diffrentiatedFields,
  vendorType,
  localData,
  foreignData,
  lastTransactionInThreadItemize
}) => {
  const classes = useStyles();
  const [destinationValues, setDestinationValues] = useState({});
  const [keyValueWithHash, setKeyValueWithHash] = useState({});
  const [keyValueWithHashGSTType, setKeyValueWithHashGSTType] = useState({});
  const [gstTypeData, setGstTypeData] = useState({});
  const [gstTypeDataMap, setGstTypeDataMap] = useState({});
  const [applyToAll, setApplyToAll] = useState(false);
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  // useEffect(() => {
  //   const gstTypeItem = itemizeData.find(
  //     (item) => item.Identifier === "GSTType"
  //   );
  //   if (gstTypeItem && gstTypeItem.DefaultValue) {
  //     setGstTypeData(gstTypeItem.DefaultValue);
  //   }
  // }, [itemizeData]);

  const extractAndFormat = (inputString) => {
    const pattern = /(\d+)\.\w\.\((f\d+)\)/g;
    const seen = new Set(); // Set to keep track of seen values
    let matches;
    const results = [];

    while ((matches = pattern.exec(inputString)) !== null) {
      const value = `${matches[1]}.${matches[2]}`;
      if (!seen.has(value)) {
        // Check if value is not already in the set
        results.push(value);
        seen.add(value); // Add value to the set
      }
    }

    return results;
  };
  useEffect(() => {
    // const gstTypeField = itemizeData.find(
    //   (item) => item.Identifier === "GSTType"
    // );

    const gstTypeField =
      itemizeData?.find((item) => item?.Identifier === "GSTType") ||
      localData?.find((item) => item?.Identifier === "GSTType") ||
      foreignData?.find((item) => item?.Identifier === "GSTType");
    let selectedItems = Object.values(lastTransactionInThreadItemize).filter(
      (main) => {
        return main?.selected == "Y";
      }
    );
    if (gstTypeField && gstTypeField.DefaultValue) {
      setGstTypeData(gstTypeField.DefaultValue);
    }
    if (gstTypeField) {
      const key = gstTypeField.FieldId;
      let value =
        lastTransactionInThreadCommon[key] ||
        selectedItems[0]?.solution[0]?.data[0][gstTypeField.FieldId];

      if (!value && gstTypeField.DefaultFormula) {
        const formula = gstTypeField.DefaultFormula;
        const fieldIds = extractAndFormat(formula);
        if (fieldIds) {
          const cleanFieldIds = fieldIds.map((id) => id);
          for (const id of cleanFieldIds) {
            const attributeKey = id.split(".");
            const subStringAttrKey = attributeKey[1].substring(1);
            if (
              lastTransactionInThreadCommon.hasOwnProperty(subStringAttrKey)
            ) {
              value = lastTransactionInThreadCommon[subStringAttrKey];
              break;
            }
          }
        }
      }
      if (value) {
        setKeyValueWithHashGSTType({ key, value });
      }
    }
  }, [lastTransactionInThreadCommon, itemizeData]);

  useEffect(() => {
    if (gstTypeData.length > 0) {
      let result = {};
      gstTypeData.forEach((item) => {
        result[item.code] = item.name;
      });
      setGstTypeDataMap(result);
    }
  }, [gstTypeData]);

  useEffect(() => {
    const data = vendorType === "Local" ? localData : foreignData;
    const destinationField = data.find(
      (item) =>
        item.Identifier === "Destination" ||
        item.Identifier === "DestinationForeign"
    );
    if (destinationField) {
      const key = destinationField.FieldId;
      let value = lastTransactionInThreadCommon[key];
      if (!value && destinationField.DefaultFormula) {
        const formula = destinationField.DefaultFormula;
        const fieldIds = extractAndFormat(formula);

        if (fieldIds) {
          const cleanFieldIds = fieldIds.map((id) => id);
          for (const id of cleanFieldIds) {
            const attributeKey = id.split(".");
            const subStringAttrKey = attributeKey[1].substring(1);
            if (
              lastTransactionInThreadCommon.hasOwnProperty(subStringAttrKey)
            ) {
              value = lastTransactionInThreadCommon[subStringAttrKey];
              break;
            }
          }
        }
      }

      if (value) {
        setKeyValueWithHash({ key, value });
      }
    }
  }, [lastTransactionInThreadCommon, vendorType, localData, foreignData]);
  // useEffect(() => {
  //   for (const [key, value] of Object.entries(lastTransactionInThreadCommon)) {
  //     if (typeof value === "string" && value.includes("###")) {
  //       setKeyValueWithHash({ key, value });
  //       break;
  //     }
  //   }
  // }, [lastTransactionInThreadCommon]);

  // useEffect(() => {
  //   for (const [key, value] of Object.entries(lastTransactionInThreadCommon)) {
  //     if (
  //       typeof value === "string" &&
  //       value.includes("###") &&
  //       value.includes("_")
  //     ) {
  //       console.log("keyGST = ", key, "valueGST = ", value);
  //       setKeyValueWithHashGSTType({ key, value });
  //       break;
  //     }
  //   }
  // }, [lastTransactionInThreadCommon]);

  const extractPlantDetails = (value) => {
    const parts = value.split("###");
    return parts.map((part) => {
      const lastIndex = part.lastIndexOf("-");
      const plantName = part.substring(0, lastIndex);
      const plantCode = part.substring(lastIndex + 1);
      return { plantName, plantCode };
    });
  };

  const plantDetails = keyValueWithHash.value
    ? extractPlantDetails(keyValueWithHash.value)
    : [];

  const extractPlantGSTType = (value) => {
    const parts = value.split("###");
    return parts.map((part) => {
      const gstTypeName = part;
      return gstTypeName;
    });
  };

  const GSTTypeDetails = keyValueWithHashGSTType.value
    ? extractPlantGSTType(keyValueWithHashGSTType.value)
    : [];

  useEffect(() => {
    if (keyValueWithHashGSTType.value) {
      const GSTTypeDetails = extractPlantGSTType(keyValueWithHashGSTType.value);
      const initialDestinationValues = GSTTypeDetails.reduce(
        (acc, gstType, index) => {
          acc[index] = gstType;
          return acc;
        },
        {}
      );
      setDestinationValues(initialDestinationValues);
    }
  }, [keyValueWithHashGSTType]);
  const extractValues = (data) => {
    const values = [];
    for (const key in data) {
      if (data[key][0]) {
        for (const subKey in data[key][0]) {
          values.push(data[key][0][subKey]);
        }
      }
    }
    return values;
  };
  const filterValues = (values) => {
    return values.filter(
      (value) =>
        value?.includes("###") ||
        value?.includes("Intra") ||
        value?.includes("Inter")
    );
  };
  // const uniqueValues = [...new Set(extractValues(diffrentiatedFields))];
  const uniqueValues = [
    ...new Set(filterValues(extractValues(diffrentiatedFields))),
  ];

  return (
    <QuatationDestinationDrawerRight
      anchor="right"
      open={open}
      className="SidebarDrawer"
    >
      <Card className={classes.DestViewPlant}>
        <CardHeader
          avatar={<RoomOutlinedIcon />}
          action={
            <Tooltip title="Close">
              <IconButton
                onClick={() => {
                  setIsDestinationDetailsDialogOpen(false);
                }}
                aria-label="close"
                size="small"
                sx={{ color: "var(--grey-500)" }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          }
          title={
            <Typography
              variant="subtitle1"
              fontWeight={500}
              component="h6"
              color={"secondary.main"}
            >
              View Destination
            </Typography>
          }
          sx={{
            "& .MuiCardHeader-action": {
              alignSelf: "center",
            },
            "& .MuiCardHeader-avatar": {
              mr: 1,
            },
          }}
        />
        <Divider />
        <CardContent sx={{ p: 0 }}>
          <Box
            sx={{
              height: "calc(100vh - 60px)",
              overflow: "hidden",
              overflowY: "auto",
            }}
          >
            {Array.isArray(GSTTypeDetails) && GSTTypeDetails.length > 0 && (
              <Box
                display={"flex"}
                className="PlantHead"
              >
                <Grid container alignItems={"center"}>
                  <Grid item xs={7}>
                    <Typography
                      variant="subtitle2"
                      component="div"
                      color="secondary.600"
                    >
                      DESTINATION NAME
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="subtitle2"
                      component="div"
                      color="secondary.600"
                      sx={{ marginRight: "16px" }}
                    >
                      GST TRANSITIONTYPE
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}            
            <List sx={{ p: 0 }}>
              {plantDetails.map((detail, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <Grid container spacing={1}>
                      <Grid item xs={7}>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          sx={{ wordBreak: "break-word" }}
                        >
                          {detail.plantName}
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          color="var(--grey-500)"
                        >
                          {detail.plantCode}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Tooltip
                          title={
                            (uniqueValues?.[0] &&
                              `Previous Value : ${
                                uniqueValues?.[0].split("###")[index]
                              }`) ||
                            GSTTypeDetails[index]
                          }
                        >
                          <Typography
                            variant="body2"
                            color={
                              uniqueValues?.[0] &&
                              uniqueValues?.[0].split("###")[index] !=
                                GSTTypeDetails[index] &&
                              "error.main"
                            }
                          >
                            {gstTypeDataMap[GSTTypeDetails[index]]}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
              ))}
            </List>
          </Box>
        </CardContent>
      </Card>
    </QuatationDestinationDrawerRight>
  );
};

export default DestinationDetailsDialogView;
