import React, { useState, useEffect } from "react";
import _, { debounce } from "lodash";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LandedCostRFQRight } from "../LandedCostRFQDrawer/style";
import { makeStyles } from "@mui/styles";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";


const drawerWidth = 350;

const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

export default function LandedCostRFQDrawer({
  isLandedCostRFQOpened,
  isLandedCostRFQClosed,
  data,
  mainTemplateQuotation,
  selectedCurrency,
  currencyDetails,
  transactionItemSequence,
  vendorType,
  TotalCostFIeld,
  EvalResultsKeyWise,
  currentTxn,
  from,
  RateField,
  PerCost,
  multiSolmainTemplateQuotation,
  solutionNames,
  msFlag,
  inquiryItemsId
}) {


  const classes = useStyles();
  const [totalBasicValue, setTotalBasicValue] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [RateKeyWise, setRateKeyWise] = useState(0);
  const [itemNames, setItemNames] = useState({});
  const [currencyIcon, setCurrencyIcon] = useState({});
  const [
    reorderedMainTemplateQuotationItemSequence,
    setReorderedMainTemplateQuotationItemSequence,
  ] = useState({});


  // Extract item names from the API response
  const [Toggle, setToggle] = useState({})
  useEffect(() => {
    if (data && data.items) {
      setItemNames(data.items);
    }
  }, [data]);
  // Calculate total basic value and total value
  const calculateTotals = () => {
    let basicValue = 0;
    let value = 0;
    let rateKeyWise = {};
    if (mainTemplateQuotation) {
      // Object.values(mainTemplateQuotation).forEach((item) => {
      Object.entries(mainTemplateQuotation).forEach(([key, item]) => {
        const quantity = parseFloat(item.qty);
        // let rate = msFlag ? multiSolmainTemplateQuotation[key][0]?.rate : parseFloat(item.rate)
        let rate = parseFloat(item.rate);
        if (from == "View" && msFlag) {
          rate = parseFloat(multiSolmainTemplateQuotation[key][0]?.rate)
        }
        
        if (isNaN(rate)) {
          if (RateField && from == "View") {
            rate =
            currentTxn?.template?.itemize?.[key]?.solution[0].data[0]?.[
              RateField.FieldId
            ];
          } else if (RateField && from != "View") {
            rate = EvalResultsKeyWise[key][RateField.FieldId];
          }
        }
        rateKeyWise[key] = rate;
        // Convert undefined, null, or NaN to zero
        const validQuantity = isNaN(quantity) ? 0 : quantity;
        const validRate = isNaN(rate) ? 0 : rate;

        const itemValue = validQuantity * validRate;

        let totalValue = 0;
        if (from === "View") {
          if (PerCost) {
            totalValue =
              (currentTxn?.template?.itemize?.[key]?.solution[0].data[0]?.[
                TotalCostFIeld.FieldId
              ] /
                (mainTemplateQuotation[key][PerCost.FieldId]["value"] || 0)) *
              validQuantity;
          } else {
            totalValue =
              currentTxn?.template?.itemize?.[key]?.solution[0].data[0]?.[
              TotalCostFIeld.FieldId
              ] * validQuantity;
          }
        } else {
          if (PerCost) {
            totalValue =
              (EvalResultsKeyWise[key][TotalCostFIeld.FieldId] /
                (mainTemplateQuotation[key][PerCost.FieldId]["value"] || 0)) *
              validQuantity;
          } else {
            totalValue =
              EvalResultsKeyWise[key][TotalCostFIeld.FieldId] * validQuantity;
          }
        }
        basicValue += itemValue;
        if (totalValue) {
          value += parseFloat(totalValue);
        }
      });
      const mainTemplateQuotationMap = new Map(
        Object.entries(mainTemplateQuotation).map((item) => [item[0], item[1]])
      );

      let reorderedItemSequence = transactionItemSequence
        .filter((key) => mainTemplateQuotationMap.has(key)) // Filter keys that are present in map
        .map((key) => key);
      setReorderedMainTemplateQuotationItemSequence(reorderedItemSequence);
    }
    setTotalBasicValue(basicValue.toFixed(3));
    setTotalValue(value.toFixed(3));
    setRateKeyWise(rateKeyWise);
  };

  useEffect(() => {
    return () => {
      const resizeObserver = document.querySelector(".ResizeObserver");
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  // Call calculateTotals whenever the drawer is opened or the data changes
  useEffect(() => {
    if (isLandedCostRFQOpened.right) {
      calculateTotals();
    }
  }, [isLandedCostRFQOpened, data, mainTemplateQuotation]);
  useEffect(() => {
    setCurrencyIcon(
      // getSymbol(selectedCurrency ? selectedCurrency : currencyDetails)
      getSymbol(selectedCurrency)
    );
  }, [selectedCurrency, currencyDetails]);

  const subRows = (key) => {
    setToggle(prevState => ({
      ...prevState,
      [key]: true,
    }));
  };

  const removeSubRow = (key) => {
    setToggle(prevState => ({
      ...prevState,
      [key]: false,
    }));
  }

  return (
    <LandedCostRFQRight
      anchor="right"
      open={isLandedCostRFQOpened.right}
      className="LandedCostRFQ-Sidebar"
    // sx={{zIndex:'1301'}}
    >
      <Card variant="outlined">
        <CardHeader
          title={
            <Typography
              variant="h6"
              fontSize="18px"
              fontWeight={500}
              color={"secondary.main"}
            >
              Total Cost Details
            </Typography>
          }
          action={
            <Tooltip title="Close">
              <IconButton
                onClick={isLandedCostRFQClosed}
                aria-label="close"
                size="small"
                sx={{ color: "var(--grey-500)" }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          }
          sx={{
            "& .MuiCardHeader-action": {
              alignSelf: "center",
            },
            "& .MuiCardHeader-avatar": {
              mr: 1,
            },
          }}
        />
        <Divider />
        <CardContent sx={{ p: 0 }}>
          <Box
            sx={{
              height: "calc(100vh - 62px)",
              width: "100%",
              overflowX: "auto",
              overflowY: "auto",
            }}
          >
            <Table
              sx={{
                "& .MuiTableCell-root:first-child:not(.MuiTableFooter-root .MuiTableCell-root:first-child)": {
                  position: msFlag ? 'sticky' : '',
                  left: msFlag ? '0' : '',
                  padding: msFlag ? '6px' : '',
                }
              }}
            >
              <TableHead>
                <TableRow>
                  {msFlag && <TableCell width={40}>&nbsp;</TableCell>}
                  <TableCell width={200}>Item Name</TableCell>
                  <TableCell align="right" width={130}>
                    Quantity
                  </TableCell>
                  <TableCell align="right" width={130}>
                    Rate {currencyIcon && `(${currencyIcon})`}
                  </TableCell>
                  <TableCell align="right" width={200}>
                    Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {Object.entries(reorderedMainTemplateQuotationItemSequence || {}).map(([item, itemId]) => {
                  const itemName = data[itemId]?.name || "Unknown";
                  if (data[itemId]) {
                    const rate = parseFloat(RateKeyWise[itemId]);
                    const qty = parseFloat(mainTemplateQuotation[itemId]?.qty);
                    const validRate = isNaN(rate) ? 0 : rate;
                    const validQty = isNaN(qty) ? 0 : qty;
                    const subtotal = validRate * validQty;
                    if (from == "View") {
                      return (
                        <React.Fragment key={itemId}>
                          <TableRow className="ItemDetls-Row">
                            {msFlag && <TableCell width={40}>

                              {Object.keys(multiSolmainTemplateQuotation[itemId]).length > 1 && (!Toggle[itemId] ? (
                                <IconButton
                                  size="small"
                                  onClick={() => subRows(itemId)}
                                >
                                  <AddIcon fontSize="inherit" />
                                </IconButton>
                              ) : (
                                <IconButton
                                  size="small"
                                  onClick={() => removeSubRow(itemId)}
                                >
                                  <RemoveIcon fontSize="inherit" />
                                </IconButton>
                              ))}
                            </TableCell>}
                            <TableCell width={200}>
                              <Tooltip title={_.unescape(itemName)} placement="bottom">
                                <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                                  {_.unescape(itemName)}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="right" width={130}>
                              <Typography variant="body2" noWrap>
                                {parseFloat(validQty).toLocaleString("en-IN", {
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                })}
                              </Typography>
                            </TableCell>
                            <TableCell align="right" width={130}>
                              <Typography variant="body2" noWrap>
                                {currencyIcon}{" "}
                                {parseFloat(validRate).toLocaleString("en-IN", {
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                })}
                              </Typography>
                            </TableCell>
                            <TableCell align="right" width={200}>
                              <Typography variant="body2" noWrap>
                                {currencyIcon}{" "}
                                {parseFloat(subtotal).toLocaleString("en-IN", {
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                })}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          {Toggle[itemId] && (
                            <TableRow
                              sx={{
                                "& .MuiTableCell-root": {
                                  background: "var(--grey-50) !important"
                                }
                              }}
                            >
                              <TableCell width={40}>&nbsp;</TableCell>
                              <TableCell colSpan={4}>
                                <Typography variant="body2" fontWeight={500}>
                                  MULTIPLE SOLUTION
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {Toggle[itemId] && (
                            Object.keys(multiSolmainTemplateQuotation[itemId]).map((item, index) => {
                              const qtyMS = parseFloat(multiSolmainTemplateQuotation[itemId][item]?.qty);
                              const rateMS = parseFloat(multiSolmainTemplateQuotation[itemId][item]?.rate);                                  
                              // const rateMS = parseFloat(item?.rate);
                              // const qtyMS = parseFloat(item?.qty);                          
                              let rate = 0;
                              
                              if (from === "View") {
                                rate =
                                currentTxn?.template?.itemize?.[itemId]?.solution[index].data[0][
                                  RateField?.FieldId
                                ];
                              } else {
                                rate = EvalResultsKeyWise[itemId][index]?.[RateField?.FieldId];
                              }
                              // Convert undefined, null, or NaN to zero
                              const validRateMS = isNaN(rateMS)
                              ? isNaN(rate) ? 0 : rate
                              : rateMS;
                              const validQtyMS = isNaN(qtyMS)
                              ? 0
                              : qtyMS;
                              return <TableRow>
                                <TableCell width={40}>&nbsp;</TableCell>
                                <TableCell width={200}>

                                  <Tooltip title={_.unescape(multiSolmainTemplateQuotation[itemId][item]?.name)} placement="bottom">
                                    <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                                      {_.unescape(multiSolmainTemplateQuotation[itemId][item]?.name)}

                                    </Typography>
                                  </Tooltip>
                                </TableCell>
                                <TableCell align="right" width={130}>
                                  <Typography variant="body2" noWrap>
                                    {parseFloat(validQtyMS).toLocaleString("en-IN", {
                                      minimumFractionDigits: 3,
                                      maximumFractionDigits: 3,
                                    })}

                                  </Typography>
                                </TableCell>
                                <TableCell align="right" width={130}>
                                  <Typography variant="body2" noWrap>
                                    {currencyIcon}{" "}
                                    {parseFloat(validRateMS).toLocaleString("en-IN", {
                                      minimumFractionDigits: 3,
                                      maximumFractionDigits: 3,
                                    })}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right" width={200}>
                                  <Typography variant="body2" noWrap>
                                    {currencyIcon}{" "}
                                    {parseFloat(validRateMS * validQtyMS).toLocaleString("en-IN", {
                                      minimumFractionDigits: 3,
                                      maximumFractionDigits: 3,
                                    })}

                                  </Typography>
                                </TableCell>
                              </TableRow>
                            }
                              // console.log("item", multiSolmainTemplateQuotation[itemId], item),
                            )
                          )}
                        </React.Fragment>
                      );
                    } else {

                      return (
                        <React.Fragment key={itemId}>
                          <TableRow>
                            {msFlag && <TableCell width={40}>
                              {Object.keys(multiSolmainTemplateQuotation).length > 0 && multiSolmainTemplateQuotation[itemId]?.length >=2 && (!Toggle[itemId] ? (
                                <IconButton
                                  size="small"
                                  onClick={() => subRows(itemId)}
                                >
                                  <AddIcon fontSize="inherit" />
                                </IconButton>
                              ) : (
                                <IconButton
                                  size="small"
                                  onClick={() => removeSubRow(itemId)}
                                >
                                  <RemoveIcon fontSize="inherit" />
                                </IconButton>
                              ))}
                            </TableCell>}
                            <TableCell width={200}>
                              <Tooltip title={_.unescape(itemName)} placement="bottom">
                                <Typography variant="body2" fontWeight={500} sx={{ wordBreak: "break-word" }}>
                                  {_.unescape(itemName)}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="right" width={130}>
                              <Typography variant="body2" noWrap>
                                {parseFloat(validQty).toLocaleString("en-IN", {
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                })}
                              </Typography>
                            </TableCell>
                            <TableCell align="right" width={130}>
                              <Typography variant="body2" noWrap>
                                {currencyIcon}{" "}
                                {parseFloat(validRate).toLocaleString("en-IN", {
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                })}
                              </Typography>
                            </TableCell>
                            <TableCell align="right" width={200}>
                              <Typography variant="body2" noWrap>
                                {currencyIcon}{" "}
                                {parseFloat(subtotal).toLocaleString("en-IN", {
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                })}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          {Toggle[itemId] && (
                            <TableRow
                              sx={{
                                "& .MuiTableCell-root": {
                                  background: "var(--grey-100) !important"
                                }
                              }}
                            >
                              <TableCell width={40}>&nbsp;</TableCell>
                              <TableCell colSpan={4}>
                                <Typography variant="body2" fontWeight={500}>
                                  MULTIPLE SOLUTION
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {Toggle[itemId] && (
                            multiSolmainTemplateQuotation[itemId].map((item, index) => {
                              const rateMS = parseFloat(item?.rate);
                              const qtyMS = parseFloat(item?.qty);
                              let rate = 0;
                              if (from === "View") {
                                rate =
                                currentTxn?.template?.itemize?.[itemId]?.solution[index].data[0][
                                  RateField?.FieldId
                                ];
                              } else {
                                rate = EvalResultsKeyWise[itemId][index]?.[RateField?.FieldId];
                              }
                              // Convert undefined, null, or NaN to zero
                              const validrateMs = isNaN(rateMS)
                              ? isNaN(rate) ? 0 : rate
                              : rateMS;
                              const validQtyMS = isNaN(qtyMS)
                              ? 0
                              : qtyMS;
                              return <TableRow
                                sx={{
                                  "& .MuiTableCell-root": {
                                    background: "var(--grey-50) !important"
                                  }
                                }}
                              >
                                <TableCell width={40}>&nbsp;</TableCell>
                                <TableCell width={200}>
                                  <Tooltip title={_.unescape(solutionNames[`${itemId}-${index}`])} placement="bottom">
                                    <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                                      {_.unescape(solutionNames[`${itemId}-${index}`])}

                                    </Typography>
                                  </Tooltip>
                                </TableCell>
                                <TableCell align="right" width={130}>
                                  <Typography variant="body2" noWrap>
                                    {parseFloat(validQtyMS).toLocaleString("en-IN", {
                                      minimumFractionDigits: 3,
                                      maximumFractionDigits: 3,
                                    })}

                                  </Typography>
                                </TableCell>
                                <TableCell align="right" width={130}>
                                  <Typography variant="body2" noWrap>
                                    {currencyIcon}{" "}
                                    {parseFloat(validrateMs).toLocaleString("en-IN", {
                                      minimumFractionDigits: 3,
                                      maximumFractionDigits: 3,
                                    })}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right" width={200}>
                                  <Typography variant="body2" noWrap>
                                    {currencyIcon}{" "}
                                    {parseFloat(validrateMs * validQtyMS).toLocaleString("en-IN", {
                                      minimumFractionDigits: 3,
                                      maximumFractionDigits: 3,
                                    })}

                                  </Typography>
                                </TableCell>
                              </TableRow>
                            }
                            )
                          )}
                        </React.Fragment>
                      );
                    }
                  }
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell align="right" colSpan={msFlag ? 4 : 3}>
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="grey.500"
                    >
                      {vendorType === "Local"
                        ? "Total Basic Value"
                        : "Total Value"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="text.primary"
                      noWrap
                    >
                      {currencyIcon}{" "}
                      {parseFloat(totalBasicValue).toLocaleString("en-IN", {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      })}
                    </Typography>
                  </TableCell>
                </TableRow>

                {vendorType === "Local" && (
                  <TableRow>
                    <TableCell align="right" colSpan={msFlag ? 4 : 3}>
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color="grey.500"
                        noWrap
                      >
                        Total Value
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color="text.primary"
                        noWrap
                      >
                        {currencyIcon}{" "}
                        {parseFloat(totalValue).toLocaleString("en-IN", {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3,
                        })}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableFooter>
            </Table>
          </Box>
        </CardContent>
      </Card>
    </LandedCostRFQRight>
  );
}
