import React, { Component, createRef } from "react";
import { CustomDropzone, CustomsmallDialog } from "./style";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  CloudDownloadOutlined,
  DeleteOutline,
  RemoveCircleRounded,
} from "@mui/icons-material/";
import documentIconSVG from "../../../assets/images/document-icon.svg";

import LinearProgress from "@mui/material/LinearProgress";
import { IconButton, Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import {
  deleteFileSpecifications,
  downloadAttachmentFiles,
  uploadAttachmentFiles,
  uploadOfflineAttachmentFiles,
} from "../../../config/apiUrl";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import { connect } from "react-redux";
class UploadOfflineAttachment extends Component {
  constructor(props) {
    super(props);
    this.descriptionElementRef = createRef(null);
  }
  state = {
    selectedFiles: [],
    totalFileBucketSize: 0,
    previousFileBucketSize: 0,
    lastFieldId: null,
    percentage: 0,
    loading: false,
    fetchLoading: false,
    downloadLoading: false,
    deleteLoading: false,
    minimumFileSize: 1024, //1024 bytes = 1KB
    // maximumFileBucketSize:8388608, // 8388608 bytes = 8192KB = 8MB
    maximumFileBucketSize: 26214400,
  };
  componentDidMount() {
    // in order to wait for the element to be actually drawn, using setTimeout
    setTimeout(() => {
      if (this.descriptionElementRef.current !== null) {
        this.descriptionElementRef.current.focus();
      }
    }, 0);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.currentFieldId !== state.lastFieldId) {
      //reset, and update last index
      return {
        selectedFiles: [],
        lastFieldId: props.currentFieldId,
        totalFileBucketSize:
          props?.uploadedFiles && props.uploadedFiles.length > 0
            ? props.uploadedFiles.reduce((accumulator, file) => {
                const fileSize = parseInt(file?.file_size);
                return isNaN(fileSize) ? accumulator : accumulator + fileSize;
              }, 0)
            : 0,
      };
    } else {
      return state;
    }
  }

  handleFilesChange = (newFile) => {
    const { totalFileBucketSize } = this.state;
    let finalSize = 0;
    let invalidFiles = [];
    let index = 0;
    if (this.props.uploadedFiles?.length == 1 || this.state.selectedFiles?.length == 1) {
      this.props.openSnackbar(
        "error",
        "Only 1 file is allowed to upload in offline attachment."
      );
      return false
    }
    newFile.forEach((file) => {
      if (file.size < this.state.minimumFileSize) {
        this.props.openSnackbar(
          "error",
          `${file.name}(${this.formatBytes(
            file.size
          )}) size is less that ${this.formatBytes(
            this.state.minimumFileSize
          )}'`
        );
        invalidFiles.push(index);
      } else if (
        (this.state.selectedFiles.length > 0 &&
          this.state.selectedFiles.some(
            (item) => item.name == file.name && item.size == file.size
          )) ||
        (this.props.uploadedFiles &&
          this.props.uploadedFiles.length > 0 &&
          this.props.uploadedFiles.some(
            (item) =>
              item.user_file_name ||
              (item.file_user_name === file.name &&
                item.file_size === file.size)
          ))
      ) {
        this.props.openSnackbar("error", `${file.name} already exists`);
        invalidFiles.push(index);
      } else {
        finalSize = finalSize + file.size;
      }
      index++;
    });

    invalidFiles.forEach((cv) => {
      newFile.splice(cv, 1);
    });

    if (
      totalFileBucketSize + parseFloat(finalSize) <
      this.state.maximumFileBucketSize
    ) {
      this.setState({
        totalFileBucketSize: totalFileBucketSize + finalSize,
        selectedFiles: [...this.state.selectedFiles, ...newFile],
      });
    } else {
      this.props.openSnackbar(
        "error",
        "File upload bucket size cannot be greater than 25 MB."
      );
    }
  };

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  handleDelete = (index) => {
    const { selectedFiles, totalFileBucketSize } = this.state;
    let removeFile = selectedFiles[index];
    let newBucketSize = totalFileBucketSize - removeFile.size;
    // console.log("[Delete] New bucket size : " + newBucketSize);
    selectedFiles.splice(index, 1);
    this.setState({
      selectedFiles: selectedFiles,
      totalFileBucketSize: newBucketSize,
    });
  };

  uplaodFile = async () => {
    if (!this.state.selectedFiles.length) {
      this.props.openSnackbar("error", "No files selected!");
      return;
    }

    this.setState({ loading: true });

    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let perc = Math.floor((loaded * 100) / total);
        if (perc < 100) {
          this.setState({ percentage: perc });
        }
      },
    };

    let formData = new FormData();
    // formData.append("field_id", this.props.currentFieldId);

    this.state.selectedFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionID}`;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    this.setState({ loading: true });

    axios
      .post(uploadOfflineAttachmentFiles, formData, options)
      .then((res) => {
        this.props.openSnackbar("success", "Uploaded Successfully");
        console.log("res", res);
        this.props.setAttachmentsValue(res.data.uploaded_files);

        let totalUploadedSize = 0;
        res.data.uploaded_files.forEach((obj) => {
          console.log("obj", obj);
          totalUploadedSize += obj.file_size;
        });
        this.setState(
          {
            percentage: 100,
            loading: false,
            selectedFiles: [],
          },
          () => {
            setTimeout(() => {
              this.setState({ percentage: 0 });
            }, 1000);
          }
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.openSnackbar(
          "error",
          err.response?.data?.validation_errors
            ? err.response?.data?.validation_errors.files[0]
            : "Something went wrong"
        );
        console.log(err, "upload error");
      });
  };

  handleUploadedDelete = (file_id) => {
    this.setState({
      deleteLoading: true,
    });
    const url = deleteFileSpecifications;
    let payload = {
      file_ids: [file_id],
      updatemaster: 10,
    };
    axios
      .put(url, payload)
      .then((res) => {
        this.state.uploadedFiles.forEach((file, index) => {
          if (file.file_id === file_id) {
            this.state.uploadedFiles.splice(index, 1);
          }
        });
        this.props.openSnackbar("success", "file deleted successfully");
        this.setState({
          deleteLoading: false,
          uploadedFiles: this.state.uploadedFiles,
        });
      })
      .catch((err) => {
        console.log(err, " handleUploadedDelete error");
        this.props.openSnackbar("error", "Something went wrong");
        this.setState({
          deleteLoading: false,
        });
      });
  };

  downloadFile = (timestamp_file_name, user_file_name) => {
    const url = downloadAttachmentFiles(timestamp_file_name);
    axios
      .get(url)
      .then((res) => {
        if (res.data.file_base64) {
          let a = document.createElement("a");
          a.href = `${res.data.file_base64}`;
          a.download = user_file_name;
          a.target = "_blank";
          a.rel = "noopener noreferrer";
          a.click();
          this.setState({
            downloadLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.setState({
          downloadLoading: false,
        });
      });
  };
  render() {
    return (
      <div>
        <Backdrop
          style={{ color: "#fff", zIndex: "10000000000" }}
          open={
            this.state.fetchLoading ||
            this.state.downloadLoading ||
            this.state.deleteLoading
          }
        >
          <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>
        <Grid container>
          <Grid item lg={12}>
            <CustomsmallDialog
              scroll="body"
              maxWidth={"sm"}
              open={this.props.open}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle
                className="dialogcard_header_main"
                sx={{
                  "&:focus-visible": {
                    border: "none",
                    outline: "none",
                  },
                }}
                ref={this.descriptionElementRef}
                tabIndex={-1}
              >
                Upload Attachment
                <Box className="dialog-title-action">
                  <IconButton
                    sx={{
                      color: (theme) => theme.palette.bluegrey[500],
                    }}
                    onClick={this.props.handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent>
                <div className="dropzone">
                  <CustomDropzone
                    accept=".xls, .xlsx, .jpg, .jpeg, .csv, .doc, .txt, .pdf, .bmp, .7z, .rar, .docx, .ods"
                    onDrop={(acceptedFiles) => {
                      this.handleFilesChange(acceptedFiles);
                    }}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="dz-message document_upload_main">
                          <div className="drop_zone_area">
                            <Typography variant="p">
                              <CloudUploadIcon sx={{ mr: 1 }} /> Drag and Drop
                              or <span>Browse</span> to upload
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                  </CustomDropzone>
                </div>
                {this.state.loading && (
                  <LinearProgress
                    sx={{ marginBottom: 2, marginTop: 2, borderRadius: 5 }}
                    variant="determinate"
                    value={this.state.percentage}
                  />
                )}
                <List className="uploadfile_list upload_file_scroll">
                  {this.state.selectedFiles.length > 0 && (
                    <>
                      {this.state.selectedFiles.map((file, index) => (
                        <>
                          <ListItem
                            className="newfile"
                            secondaryAction={
                              <IconButton
                                color="error"
                                onClick={() => this.handleDelete(index)}
                                size="small"
                              >
                                <RemoveCircleRounded />
                              </IconButton>
                            }
                          >
                            <ListItemIcon>
                              <img src={documentIconSVG} />
                            </ListItemIcon>
                            <ListItemText
                              primary={file.name}
                              secondary={`${this.formatBytes(file.size)}`}
                            />
                          </ListItem>
                        </>
                      ))}
                    </>
                  )}
                  {this.props.uploadedFiles &&
                    this.props.uploadedFiles.length > 0 && (
                      <div>
                        <Typography
                          variant="p"
                          sx={{
                            textTransform: "uppercase",
                            color: (theme) => theme.palette.bluegrey[600],
                            fontWeight: 500,
                            marginBottom: "16px",
                          }}
                        >
                          Uplaoded Files
                        </Typography>
                        {this.props.uploadedFiles.map((file, index) => {
                          return (
                            <ListItem
                              secondaryAction={
                                <>
                                  <Tooltip title="Download">
                                    <IconButton
                                      sx={{
                                        mr: 1,
                                        color: (theme) =>
                                          theme.palette.grey[600],
                                      }}
                                      size="small"
                                      onClick={() => {
                                        this.downloadFile(
                                          file.timestamp_file_name,
                                          file.user_file_name ||
                                            file.file_user_name
                                        );
                                      }}
                                    >
                                      <CloudDownloadOutlined />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete">
                                    <IconButton
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.grey[600],
                                      }}
                                      onClick={() => {
                                        this.props.handleUplaodedFileDelete(
                                          index
                                        );
                                        this.setState({
                                          totalFileBucketSize:
                                            this.state.totalFileBucketSize -
                                            file.file_size,
                                        });
                                      }}
                                      size="small"
                                    >
                                      <DeleteOutline />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            >
                              <ListItemIcon>
                                <img src={documentIconSVG} />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Tooltip
                                      title={
                                        file.user_file_name ||
                                        file.file_user_name
                                      }
                                    >
                                      <Typography
                                        component="p"
                                        color="text.primary"
                                        noWrap
                                        className="filename-text"
                                      >
                                        {file.user_file_name ||
                                          file.file_user_name}
                                      </Typography>
                                    </Tooltip>
                                  </React.Fragment>
                                }
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      component="span"
                                      noWrap
                                      className="filesize-text"
                                    >
                                      {this.formatBytes(file.file_size)}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                          );
                        })}
                      </div>
                    )}
                </List>
                <Typography sx={{ fontSize: "14px", fontWeight: 500, mt: 2 }}>
                  File size should be between 1KB to 25MB
                </Typography>
                <Typography sx={{ fontSize: "12px", color: "grey.500" }}>
                  Note: The file should be of one of the following file
                  types(*.jpg,*.jpeg,*.xls,*.xlsx,.csv*,*.tsv,*.doc,*.txt,*.pdf,*.bmp,*.7z,*.eml,*.rar,
                  *.docx,*.cdr, *.ods)
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.props.handleClose}
                  autoFocus
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.uplaodFile}
                  disabled={this.state.loading}
                  autoFocus
                >
                  Add File
                </Button>
              </DialogActions>
            </CustomsmallDialog>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(null, {
  openSnackbar,
})(UploadOfflineAttachment);
