import React, { useEffect, useState } from "react";
import {
  IconButton,
  Typography,
  Box,
  Divider,
  FormControlLabel,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  List,
  ListItem,
  CardHeader,
  Card,
  Tooltip,
  CardContent,
  Button,
  Checkbox,
  Snackbar,
  CardActions,
} from "@mui/material";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { Close as CloseIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { QuatationDestinationDrawerRight } from "./style";
import {
  CustomAlert,
  CustomSnackbar,
} from "../../../components/Snackbar/style";

const useStyles = makeStyles(() => ({
  DestViewPlant: {
    "& .PlantHead": {
      background: "var(--grey-100)",
      borderBottom: "1px solid var(--bluegrey-500)",
      padding: "2px 16px",
      height: "32px",
      "& .MuiButtonBase-root": {
        padding: "2px",
        marginRight: "2px",
      },
      "& .MuiFormControlLabel-label": {
        fontSize: "14px",
        fontWeight: "500",
      },
      "& .MuiFormControlLabel-asterisk": {
        color: "var(--error-900)",
      },
    },
  },
}));

const DestinationDetailsDialog = ({
  open,
  handleSave,
  itemizeData,
  setIsDestinationDetailsDialogOpen,
  lastTransactionInThreadCommon,
  foreignData,
  localData,
  vendorType,
  fetchedTxn,
  additionalInfo,
  mainTemplateInfo,
}) => {
  const classes = useStyles();
  const [destinationValues, setDestinationValues] = useState({});
  const [keyValueWithHash, setKeyValueWithHash] = useState({});
  const [keyValueWithHashGSTType, setKeyValueWithHashGSTType] = useState({});
  const [gstTypeData, setGstTypeData] = useState({});
  const [applyToAll, setApplyToAll] = useState(false);
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  // Extract plant details function
  const extractPlantDetails = (value) => {
    const parts = value.split("###");
    return parts.map((part) => {
      const lastIndex = part.lastIndexOf("-");
      const plantName = part.substring(0, lastIndex);
      const plantCode = part.substring(lastIndex + 1);
      return { plantName, plantCode };
    });
  };

  useEffect(() => {
    handleSaveClick();
  }, []);

  useEffect(() => {
    if (Object.keys(keyValueWithHashGSTType).length > 0) {
      let gstTypes = keyValueWithHashGSTType.value.split("###");
      const result = gstTypes.reduce((acc, current, index) => {
        acc[index] = current;
        return acc;
      }, {});
      setDestinationValues(result);
    }
  }, [keyValueWithHashGSTType]);

  useEffect(() => {
    const gstTypeItem =
      itemizeData?.find((item) => item?.Identifier === "GSTType") ||
      localData?.find((item) => item?.Identifier === "GSTType") ||
      foreignData?.find((item) => item?.Identifier === "GSTType");

    if (gstTypeItem && gstTypeItem?.DefaultValue) {
      setGstTypeData(gstTypeItem?.DefaultValue);
    }
  }, [itemizeData]);

  const extractAndFormat = (inputString) => {
    const pattern = /(\d+)\.\w\.\((f\d+)\)/g;
    const seen = new Set(); // Set to keep track of seen values
    let matches;
    const results = [];

    while ((matches = pattern.exec(inputString)) !== null) {
      const value = `${matches[1]}.${matches[2]}`;
      if (!seen.has(value)) {
        // Check if value is not already in the set
        results.push(value);
        seen.add(value); // Add value to the set
      }
    }

    return results;
  };
  useEffect(() => {
    const data = vendorType === "Local" ? localData : foreignData;
    const destinationField = data.find(
      (item) =>
        item.Identifier === "Destination" ||
        item.Identifier === "DestinationForeign"
    );
    if (destinationField) {
      const key = destinationField.FieldId;
      let value = lastTransactionInThreadCommon[key];
      if (!value && destinationField.DefaultFormula) {
        const formula = destinationField.DefaultFormula;
        const fieldIds = extractAndFormat(formula);

        if (fieldIds) {
          const cleanFieldIds = fieldIds.map((id) => id);
          for (const id of cleanFieldIds) {
            const attributeKey = id.split(".");
            const subStringAttrKey = attributeKey[1].substring(1);
            if (
              lastTransactionInThreadCommon.hasOwnProperty(subStringAttrKey)
            ) {
              value = lastTransactionInThreadCommon[subStringAttrKey];
              break;
            } else if (
              fetchedTxn?.[attributeKey[0]]?.common.hasOwnProperty(
                subStringAttrKey
              )
            ) {
              value = fetchedTxn?.[attributeKey[0]]?.common[subStringAttrKey];
              break;
            }
          }
        }
      }

      if (value) {
        setKeyValueWithHash({ key, value });
      }
    }
  }, [lastTransactionInThreadCommon, vendorType, localData, foreignData]);

  useEffect(() => {
    const gstTypeField =
      itemizeData.find((item) => item.Identifier === "GSTType") ||
      foreignData.find((item) => item.Identifier === "GSTType");
      let selectedItems = Object.values(mainTemplateInfo).filter(main => {
        return main?.selected == "Y"
      })

    if (gstTypeField) {
      const key = gstTypeField.FieldId;
      let value = "";
      if (additionalInfo?.hasOwnProperty(key)) {
        value = additionalInfo[key]["value"];
      } else if (Object.values(selectedItems)[0]?.hasOwnProperty(key)) {
        value = Object.values(selectedItems)[0][key]["value"];
      }
      if (value) {
        setKeyValueWithHashGSTType({ key, value });
      }
    }
  }, [
    lastTransactionInThreadCommon,
    itemizeData,
    additionalInfo,
    mainTemplateInfo,
  ]);

  const handleChangeDestination = (event, index) => {
    const value = event.target.value;
    setErrors(value ? {} : { [index]: true });
    setDestinationValues({
      ...destinationValues,
      [index]: value,
    });
  };
  const openSnackbar = (severity, message) => {
    setSnackbar({ open: true, message, severity });
  };

  const plantDetails = keyValueWithHash.value
    ? extractPlantDetails(keyValueWithHash.value)
    : [];
  const handleApplyToAllChange = (event) => {
    if (!applyToAll) {
      if (!destinationValues[0]) {
        openSnackbar("error", "Please select a value in the first select box");
        return;
      }

      if (event.target.checked) {
        const firstValue = destinationValues[0] || "";
        const updatedValues = {};
        plantDetails.forEach((_, idx) => {
          updatedValues[idx] = firstValue;
        });
        setDestinationValues(updatedValues);
      }
      setApplyToAll(event.target.checked);
    }
  };

  const checkForErrors = () => {
    const newErrors = {};

    plantDetails.forEach((_, index) => {
      if (!destinationValues[index]) {
        newErrors[index] = true;
      }
    });
    const values = Object.values(destinationValues); // Get all values from the object
    const firstValue = values[0]; // Get the first value

    if (values.every((value) => value === firstValue) == false) {
      setApplyToAll(false);
    }
    setErrors(newErrors);
  };

  useEffect(() => {
    checkForErrors();
  }, [keyValueWithHash, destinationValues]);

  const handleSaveClick = () => {
    try {
      if (Object.keys(errors).length > 0) {
        openSnackbar("error", "Please select a value for all destinations.");
        return;
      }

      const savedValues = Object.values(destinationValues).join("###");
      handleSave(savedValues);
      setIsDestinationDetailsDialogOpen(false);
    } catch (error) {
      console.error("Error in handleSaveClick:", error);
      openSnackbar(
        "error",
        "An error occurred while saving. Please try again."
      );
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <QuatationDestinationDrawerRight
      anchor="right"
      open={open}
      className="SidebarDrawer"
    >
      <Card className={classes.DestViewPlant}>
        <CardHeader
          avatar={<RoomOutlinedIcon />}
          action={
            <Tooltip title="Close">
              <IconButton
                onClick={() => {
                  setIsDestinationDetailsDialogOpen(false);
                }}
                aria-label="close"
                size="small"
                sx={{ color: "var(--grey-500)" }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          }
          title={
            <Typography
              variant="subtitle1"
              fontWeight={500}
              component="h6"
              color={"secondary.main"}
            >
              View Destination
            </Typography>
          }
          sx={{
            "& .MuiCardHeader-action": {
              alignSelf: "center",
            },
            "& .MuiCardHeader-avatar": {
              mr: 1,
            },
          }}
        />
        <Divider />
        <CardContent sx={{ p: 0 }}>
          <Box
            sx={{
              height: "calc(100vh - 109px)",
              overflow: "hidden",
              overflowY: "auto",
            }}
          >
            {Array.isArray(gstTypeData) && gstTypeData.length > 0 && (
              <Box display={"flex"} className="PlantHead">
                <Grid container alignItems={"center"}>
                  <Grid item xs={7}>
                    <Typography
                      variant="subtitle2"
                      component="div"
                      color="secondary.600"
                    >
                      DESTINATION NAME
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={applyToAll}
                          // color="primary"
                          onChange={handleApplyToAllChange}
                        />
                      }
                      label="GST TRANSITIONTYPE"
                      required
                      sx={{ marginRight: "16px", ml: 0 }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
            <List sx={{ p: 0 }}>
              {plantDetails.map((detail, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <Grid container spacing={1}>
                      <Grid item xs={7}>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          sx={{ wordBreak: "break-word" }}
                        >
                          {detail.plantName}
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          color="var(--grey-500)"
                        >
                          {detail.plantCode}
                        </Typography>
                      </Grid>
                      {Array.isArray(gstTypeData) && gstTypeData.length > 0 && (
                        <Grid item xs={5}>
                          <FormControl
                            sx={{
                              marginTop: "4px",
                            }}
                            fullWidth
                            size="small"
                            error={errors[index]}
                          >
                            <InputLabel id={`select-label-${index}`}>
                              Select
                            </InputLabel>
                            <Select
                              value={destinationValues[index] || ""}
                              labelId={`select-label-${index}`}
                              label="Select"
                              onChange={(e) =>
                                handleChangeDestination(e, index)
                              }
                            >
                              {gstTypeData?.map((option, idx) => (
                                <MenuItem key={idx} value={option.code}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors[index] && (
                              <Typography
                                variant="caption"
                                color="error"
                                textAlign={"left"}
                                lineHeight={"normal"}
                                mt={1}
                              >
                                GST TransitionType field is required.
                              </Typography>
                            )}
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
              ))}
            </List>
          </Box>
        </CardContent>

        {Array.isArray(gstTypeData) && gstTypeData.length > 0 && (
          <>
            <Divider />
            <CardActions
              sx={{ pl: 2, pr: 2, justifyContent: "end", gap: "8px" }}
            >
              <Typography variant="body2" fontWeight={500} sx={{ mr: "auto" }}>
                Note: Please save it before closing.
              </Typography>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setIsDestinationDetailsDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleSaveClick}
              >
                Save
              </Button>
            </CardActions>
          </>
        )}
      </Card>
      <CustomSnackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }} // Positioning Snackbar at top-right
      >
        {
          <CustomAlert
            onClose={handleCloseSnackbar}
            severity="error"
            variant="filled"
          >
            {snackbar.message}
          </CustomAlert>
        }
      </CustomSnackbar>
    </QuatationDestinationDrawerRight>
  );
};

export default DestinationDetailsDialog;
