import React, { useState, useEffect } from "react";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/CloudUploadRounded";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import dayjs from "dayjs";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import UploadFileDialog from "../../Dialogs/Upload/Upload";
import { TrendingUp } from "@mui/icons-material";
import TermsAndConditions from "../../Dialogs/Upload/TermsAndConditions";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import UploadDynamicView from "../../Dialogs/Upload/UploadDynamicView";
import { parentHost, postFileSpecifications, previewActivity } from "../../../config/apiUrl";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const QuotationDetailsSectionView = (props) => {
  const [dynamicFileUploadOpen, setDynamicFileUploadOpen] = useState(false);
  const [specificationsOpen, setSpecificationsOpen] = useState(false);
  const [termsAndConditionsPopUp, setTermsAndConditionsPopUp] = useState(false);
  const [categorySpecificationFiles, setCategorySpecificationFiles] = useState(
    []
  );
  const [currentFieldId, setCurrentFieldId] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date()); // State to hold the current date
  const [Loading, setLoading] = useState(true);
  // const [currentTemplate, setCurrentTemplate] = useState("IntlTemplate");
  const [offlineAttachment, setofflineAttachment] = useState([]);
  const [DiffrentValidityDate, setDiffrentValidityDate] = useState({});
  const [quotationDetails, setQuotationDetails] = useState({
    Quotation: {
      //(type=Inquiry)
      offlineQuotationNo: "",
      rfqRefNo: "",
      currentDate: null,
      rfqDate: null,
      offlineQuotationDate: null,
      attachment: {}, //Attachment for Offline Quotation
      rfqValidityDate: null,
      quotationValidity: null,
      reference: "",
      quoteReceivedDate: "",
      currency: "",
      Specifications: [],
    },
    // Negotiation: {
    //   //Negotiation( type = Negotiation)
    //   offlineRequoteNo: "",
    //   quotationRefNo: "",
    //   negotiationRefNo: "",
    //   currentDate: null,
    //   negotiationDate: null,
    //   offlineRequoteDate: null,
    //   attachment: "",
    //   negotiationValidityDate: null,
    //   requoteValidity: null,
    //   reference: "",
    // },
    Requote: {
      //Requote 1 ( Type = Quotation )
      offlineRequoteNo: "",
      rfqRefNo: "",
      quotationRefNo: "",
      offlineRquoteDate: null,
      offlineRequoteValidity: "",
      offlineRequoteReceivedDate: "",
      attachment: {},
      reference: "",

      // previousOfflineQuoteRequoteDate: null,
      // offlineRequoteDate: null,
      // attachment: "",
      // quotationValidityDate: null,
      // requoteValidity: null,
      // reference: "",
    },
    // Requote: {
    //   //Requote 2 (type = Requote)
    //   offlineRequoteNo: "",
    //   requoteRefNo: "",
    //   currentDate: null,
    //   requoteDate: null,
    //   previousOfflineQuoteRequoteDate: null,
    //   offlineRequoteDate: null,
    //   attachment: "",
    //   requoteValidityDate: null,
    //   requoteValidity: null,
    //   reference: "",
    // },
  });
  const [IframeVisible, setIframeVisible] = React.useState(false);
  const queryParams = new URLSearchParams(window.location.search);


  const condition = props?.data?.vendors[0]?.transaction_thread
    .slice()
    .reverse() // Reverse the array to find the last matching element first
    .find((item) => item?.template)?.type;

  const quotationDetailsData = props.CurrentTransaction;

  const getFieldsByCondition = (condition) => {
    let refLabel = "";
    let prevTranDate = "";
    if (props.PrevTransaction?.type == "Negotiation") {
      refLabel = "Negotiation Ref. No.";
      prevTranDate = "Date of Negotiation";
    } else if (props.PrevTransaction?.type == "Requote") {
      refLabel = "Requote Ref. No.";
      prevTranDate = "Date of Requote";
    } else {
      refLabel = "Quotation Ref. No.";
      prevTranDate = "Date of Quotation";
    }
    switch (condition) {
      case "Quotation":
        return [
          {
            id: "1",
            name: "offlineQuotationNo",
            label: "Offline Quotation No.",
            isRequired: false,
            isReadOnly: false,
          },
          {
            id: "2",
            name: "rfqRefNo",
            label: "RFQ Ref. No.",
            isRequired: false,
            isReadOnly: false,
          },
          // {
          //   id: "3",
          //   name: "currentDate",
          //   label: "Current Date",
          //   type: "date",
          //   isRequired: false,
          //   isReadOnly: true,
          // },
          {
            id: "3",
            name: "rfqDate",
            label: "Date of RFQ",
            type: "date",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "4",
            name: "offlineQuotationDate",
            label: "Offline Quotation Date",
            type: "date",
            isRequired: false,
            isReadOnly: false,
          },
          // {
          //   id: "5",
          //   name: "Specifications",
          //   label: "View Specifications",
          //   type: "file",
          //   isRequired: true,
          //   isReadOnly: false,
          // },
          // {
          //   id: "7",
          //   name: "rfqValidityDate",
          //   label: "RFQ Validity Date",
          //   type: "date",
          //   isRequired: false,
          //   isReadOnly: true,
          // },
          {
            id: "6",
            name: "quotationValidity",
            label: "Offline Quotation Validity",
            type: "date",
            isRequired: false,
            isReadOnly: false,
          },
          {
            id: "7",
            name: "quoteReceivedDate",
            label: "Offline Quotation Received Date",
            isRequired: false,
            isReadOnly: false,
          },
          {
            id: "8",
            name: "reference",
            label: "Reference",
            isRequired: true,
            isReadOnly: false,
          },
          // {
          //   id: "9",
          //   name: "currency",
          //   label: "Currency",
          //   isRequired: true,
          //   isReadOnly: false,
          // },
          {
            id: "10",
            name: "attachment",
            label: "Offline Quotation Attachment",
            type: "file",
            isRequired: false,
            isReadOnly: false,
          },
        ].filter((field) => {
          // Filter out fields that do not have a value

          return quotationDetails[condition][field.name];
        });
      case "Requote":
        return [
          {
            id: "1",
            name: "offlineRequoteNo",
            label: "Offline Requote No.",
            isRequired: true,
            isReadOnly: false,
          },
          {
            id: "2",
            name: "rfqRefNo",
            label: "RFQ Ref. No.",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "3",
            name: "quotationRefNo",
            label: refLabel,
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "4",
            name: "offlineRquoteDate",
            label: "Offline Requote Date",
            type: "date",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "5",
            name: "quotationDate",
            label: prevTranDate,
            type: "date",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "6",
            name: "offlineRequoteValidity",
            label: "Offline Requote Validity",
            type: "date",
            isRequired: false,
            isReadOnly: false,
          },
          {
            id: "7",
            name: "offlineRequoteReceivedDate",
            label: "Offline Requote Received Date	",
            type: "date",
            isRequired: false,
            isReadOnly: false,
          },
          {
            id: "8",
            name: "attachment",
            label: "Attchament for Offline Requote",
            type: "file",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "9",
            name: "reference",
            label: "Reference",
            isRequired: true,
            isReadOnly: false,
          },
        ].filter((field) => {
          // Filter out fields that do not have a value

          return quotationDetails[condition][field.name];
        });
      default:
        return [];
    }
  };
  const conditionFields = getFieldsByCondition(condition);
  const setValidityDate = (prevQuotationDetails, date, type) => ({
    ...prevQuotationDetails,
    [`${type}Validity`]: dayjs(date, "YYYY-MM-DD HH:mm:ss").format(
      `DD/MM/YYYY HH:mm:ss [${date.split(" ").pop()}]`
    ),
  });
  useEffect(() => {
    if (props?.data) {
      const { expiry_at, validity_date, action_date, ref_no, rfq_date, no } =
        props.data;

      const propsData = quotationDetailsData;
      const formattedDate = expiry_at;
      const rfqDate = dayjs(rfq_date, "YYYY-MM-DD HH:mm:ss").format(
        `DD/MM/YYYY HH:mm:ss [${rfq_date.split(" ").pop()}]`
      );
      if (props?.PrevTransaction?.type === "Inquiry") {
        if (props?.data?.expiry_at !== propsData?.validity_date) {
          const date = props?.data?.expiry_at;
          const type =
            propsData?.type === "Quotation" ? "quotation" : "offlineRequote";
          setDiffrentValidityDate((prevQuotationDetails) =>
            setValidityDate(prevQuotationDetails, date, type)
          );
        }
      } else {
        if (props?.PrevTransaction?.validity_date !== propsData?.validity_date) {
          const date = props?.PrevTransaction?.validity_date;
          const type =
            propsData?.type === "Quotation" ? "quotation" : "offlineRequote";
          setDiffrentValidityDate((prevQuotationDetails) =>
            setValidityDate(prevQuotationDetails, date, type)
          );
        }
      }
      const quotationDate = dayjs(
        propsData?.action_date,
        "YYYY-MM-DD HH:mm:ss"
      ).format(
        `DD/MM/YYYY HH:mm:ss [${propsData?.action_date.split(" ").pop()}]`
      );

      const validityDate = dayjs(
        propsData?.validity_date,
        "YYYY-MM-DD HH:mm:ss"
      ).format(
        `DD/MM/YYYY HH:mm:ss [${propsData?.validity_date.split(" ").pop()}]`
      );

      const quoteReceivedDate = propsData?.quote_received_date
        ? dayjs(propsData?.quote_received_date, "YYYY-MM-DD HH:mm:ss").format(
          `DD/MM/YYYY HH:mm:ss [${propsData?.quote_received_date
            .split(" ")
            .pop()}]`
        )
        : null;
      const currency =
        props?.data?.vendors[0]?.type != "Local"
          ? props?.data?.vendors[0]?.quoted_currency
          : null;
      const specifictionFile =
        props?.data?.categories[0]?.files?.length > 0
          ? props?.data?.categories[0]?.files
          : null;
      const attachment =
        propsData?.Offline_attachment?.length > 0
          ? propsData?.Offline_attachment
          : null;
      const quotationRefNo = props?.data?.vendors[0]?.transaction_thread.find(
        (quotation) => {
          return quotation?.type == props?.PrevTransaction?.type;
        }
      );
      //dayjs(propsData?.quote_received_date, "YYYY-MM-DD").toDate();
      setCurrentDate(new Date());
      setQuotationDetails((prevQuotationDetails) => ({
        ...prevQuotationDetails,
        Quotation: {
          ...prevQuotationDetails.Inquiry,
          offlineQuotationNo: propsData?.no,
          rfqRefNo: ref_no,
          currentDate: currentDate,
          rfqDate: rfqDate,
          offlineQuotationDate: quotationDate,
          rfqValidityDate: quotationDate,
          quotationValidity: validityDate,
          reference: propsData?.reference,
          quoteReceivedDate: quoteReceivedDate,
          currency: currency,
          Specifications: specifictionFile,
          attachment: attachment,
        },
        Requote: {
          ...prevQuotationDetails.Quotation,
          offlineRequoteNo: propsData?.no,
          rfqRefNo: props?.data?.ref_no,
          quotationRefNo: props.PrevTransaction?.no,
          offlineRquoteDate: quotationDate,
          quotationDate: dayjs(
            props.PrevTransaction?.action_date,
            "YYYY-MM-DD HH:mm:ss"
          ).format(
            `DD/MM/YYYY HH:mm:ss [${props.PrevTransaction?.action_date
              ?.split(" ")
              .pop()}]`
          ),
          offlineRequoteValidity: validityDate,
          offlineRequoteReceivedDate: quoteReceivedDate,
          attachment: attachment,
          reference: propsData?.reference,

          // currentDate: formattedDate,
          // quotationDate: formattedDate,
          // previousOfflineQuoteRequoteDate: formattedDate,
          // offlineRequoteDate: formattedDate,
          // quotationValidityDate: formattedDate,
          // requoteValidity: formattedDate,
        },
      }));
    }
  }, [props.data]);
  useEffect(() => {
    // Event listener to handle messages from the iframe
    const handleMessage = (event) => {
      if (event.origin !== parentHost) return;

    
      if (event.data.excelClose) {
        setIframeVisible(false);
      }
    };
    window.addEventListener("message", handleMessage);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  const handleUplaodedFileDelete = (index) => {
    let temp = { ...props[props.currentTemplate] };
    temp[props.currentFieldId]["value"].splice(index, 1);
    props.setState({ [props.currentTemplate]: temp });
  };

  const handleUploadClose = () => {
    setDynamicFileUploadOpen(false);
    setofflineAttachment([]);
    setLoading(true);
  };

  const handleUploadOpen = (value) => {
    setDynamicFileUploadOpen(true);
    getExistingFiles(value);
  };
  const getExistingFiles = (file_id) => {
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");

    const url = `${postFileSpecifications}?${queryString}`;
    axios
      .get(url)
      .then((res) => {
        setofflineAttachment(res.data);
        setLoading(false);
      })
      .catch((err) => {
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        setLoading(false);
      });
  };
  const handleUplaodSpecificationOpen = () => {
    setSpecificationsOpen(true);
  };

  const handleTermsAndConditionsOpen = () => {
    termsAndConditionsPopUp(true);
  };

  const handleFieldChange = (condition, fieldName) => (event) => {
    setQuotationDetails({
      ...quotationDetails,
      [condition]: {
        ...quotationDetails[condition],
        [fieldName]: event.target.value,
      },
    });
  };

  const handleDateChange = (condition, fieldName) => (newVal) => {
    if (!newVal) {
      setQuotationDetails({
        ...quotationDetails,
        [condition]: {
          ...quotationDetails[condition],
          [fieldName]: null,
        },
      });
    } else {
      setQuotationDetails({
        ...quotationDetails,
        [condition]: {
          ...quotationDetails[condition],
          [fieldName]: newVal,
        },
      });
    }
  };



  // fetchTermsAndCondtions = () => {
  //   this.toggleLoaderArray("TermsAndCondtions");
  //   let newTermsAndConditions = this.props.termsAndConditions;
  //   let categoryId = this.props.selectedCategory?.id;
  //   if (
  //     Object.keys(newTermsAndConditions).length !== 0 &&
  //     newTermsAndConditions[categoryId]
  //   ) {
  //     this.setState({
  //       termsAndConditionsData: newTermsAndConditions[categoryId],
  //     });
  //     this.toggleLoaderArray("TermsAndCondtions");
  //   } else {
  //     const url = getTermsAndConditions(categoryId);
  //     axios
  //       .get(url)
  //       .then((res) => {
  //         this.setState({
  //           termsAndConditionsData: res.data?.data,
  //         });
  //         this.props.setTermsAndConditions({ [categoryId]: res.data?.data });
  //         this.toggleLoaderArray("TermsAndCondtions");
  //       })
  //       .catch((err) => {
  //         console.log(err, "getfilespecs error");
  //         this.toggleLoaderArray("TermsAndCondtions");
  //       });
  //   }
  // };

  const renderField = (condition, field) => {
    // const status = "Offline";
    // console.log('props?.action_role', );
    const status =
      props.CurrentTransaction?.action_role == "buyer" ? "Offline" : "Online";

    // const status = "Online"; // Assuming this is where you get the current status from the API
    const label =
      status === "Online" && field.label.includes("Offline")
        ? field.label.replace("Offline ", "")
        : field.label;
    if (
      (status === "Online" &&
        // ["offlineQuotationDate", "attachment", "reference"].includes(
        ["attachment", "reference"].includes(field.name)) ||
      // Exclude fields based on any other condition or criteria here
      false
    ) {
      return null; // Don't render the field
    }
    const value = quotationDetails[condition][field.name];
    if (field.name === "attachment" && field.type === "file") {
      return (
        <FormControl>
          {/* <Box
            sx={{
              // border: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderRadius: "4px",
              minWidth: "100%",
              // height: "56px",
              marginBottom: "5px",
              display: "flex",
              alignItems: "center",
              // padding: "0 10px",
              position: "relative",
            }}
          > */}
          {/* <InputLabel
              sx={{
                color: "text.secondary",
                backgroundColor: (theme) => theme.palette.grey[0],
                position: "absolute",
                left: 0,
                top: 0,
                paddingLeft: "5px",
                paddingRight: "5px",
                transformOrigin: "top left",
                webkitTransform: " translate(14px, -9px) scale(0.75)",
                transform: "translate(14px, -9px) scale(0.75)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "calc(133% - 24px)",
                zIndex: 1,
              }}
            > */}
          {/* {label} */}
          {/* </InputLabel> */}
          {/* <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ fontSize: "12px" }}
              onClick={() => {
                handleUploadOpen(value);
                // field.name === "attachment"
                //   ? handleUplaodSpecificationOpen()
                //   : handleTermsAndConditionsOpen();
              }}
            >
              <UploadIcon sx={{ marginRight: "10px" }} />
              {field.label}
            </Button>
          </Box> */}
          <Typography
            component="label"
            variant="body2"
            color={"grey.500"}
            mb={1}
          >
            {label}
          </Typography>
          <Box>
            <Badge badgeContent={`${value?.length}`} color="error">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  handleUploadOpen(value);
                  // field.name === "attachment"
                  //   ? handleUplaodSpecificationOpen()
                  //   : handleTermsAndConditionsOpen();
                }}
                startIcon={
                  <VisibilityIcon sx={{ fontSize: "22px !important" }} />
                }
              >
                View Attachment
                {/* {field.label} */}
              </Button>
            </Badge>
          </Box>
          {/* <Typography mt={1} variant="body2" color={"text.primary"}>Number Of Files {value?.length}</Typography> */}
          <Typography>
            {/* {this.state.localTemplate[cell.HTMLAttributeName]} */}
          </Typography>
          {/* {error && <FormHelperText>{ErrorMessage}</FormHelperText>} */}
        </FormControl>
      );
    } else if (
      field.name === "Specifications" &&
      field.type === "file" &&
      props?.data?.categories[0]?.files?.length > 0
    ) {
      return (
        <FormControl sx={{ width: "100%" }}>
          <Box
            sx={{
              // border: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderRadius: "4px",
              minWidth: "100%",
              // height: "56px",
              marginBottom: "5px",
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            {/* <InputLabel
              sx={{
                color: "text.secondary",
                backgroundColor: (theme) => theme.palette.grey[0],
                position: "absolute",
                left: 0,
                top: 0,
                paddingLeft: "5px",
                paddingRight: "5px",
                transformOrigin: "top left",
                webkitTransform: " translate(14px, -9px) scale(0.75)",
                transform: "translate(14px, -9px) scale(0.75)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "calc(133% - 24px)",
                zIndex: 1,
              }}
            > */}
            {/* {label} */}
            {/* </InputLabel> */}
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ fontSize: "12px" }}
              onClick={() => {
                handleUplaodSpecificationOpen();
              }}
            >
              <UploadIcon sx={{ marginRight: "10px" }} />
              {field.label}
            </Button>
          </Box>
          <Typography>Number Of Files {value.length}</Typography>
          <Typography>
            {/* {this.state.localTemplate[cell.HTMLAttributeName]} */}
          </Typography>
          {/* {error && <FormHelperText>{ErrorMessage}</FormHelperText>} */}
        </FormControl>
      );
    } else {
      if (!value) {
        return null;
      }
      return (
        <>
          <Typography component="label" variant="body2" color={"grey.500"}>
            {label}
          </Typography>

          <Tooltip
            title={
              DiffrentValidityDate?.[field.name]
                ? `Previous Value : ${DiffrentValidityDate?.[field.name]}`
                : value
            }
            placement="bottom-start"
          >
            <Typography
              variant="body2"
              color={
                DiffrentValidityDate?.[field.name]
                  ? "error.main"
                  : "text.primary"
              }
            >
              {value}
            </Typography>
          </Tooltip>
        </>
      );
    }
  };

  return (
    <>
      <Card
        variant="outlined"
        // className="template-card local_recepients_card"
        id="QuotationDetails"
      >
        <CardHeader
          title={props.title}
          titleTypographyProps={{
            variant: "subtitle2",
            component: "h5",
            color: "grey.900",
            textTransform: "uppercase",
          }}
          sx={{
            minHeight: "50px",
            "& .MuiCardHeader-action": {
              marginRight: 0,
              marginTop: 0,
            },
          }}
          action={
            <>
              {props.isServiceRfq && <Button
                variant="text"
                onClick={() => {
                  // PreviewActivity()
                  setIframeVisible(true)
                }}
                sx={{
                  textDecoration: "underline",
                  textTransform: "capitalize",
                  p: 0,
                  "&:hover": {
                    backgroundColor: "transparent", // Set background to transparent
                  },
                }}
              >
               {`View ${props.AlternativeNames?.service_option || "Activity"} Details`}
              </Button>}
            </>
          }
        />
        <Divider sx={{ marginBottom: "0" }} />
        <CardContent>
          {/* <Grid container spacing={2} className="local_recepients_card_content"> */}
          <Grid container spacing={2}>
            {conditionFields.map((field) => {
              const renderedField = renderField(`${condition}`, field);
              return (
                renderedField && (
                  <Grid item key={field.id} xl={3} lg={4} md={4} sm={6} xs={12}>
                    {renderedField}
                  </Grid>
                )
              );
            })}
            {/* {conditionFields.map((field) => (
              <Grid item key={field.id} xl={3} lg={4} md={4} sm={6} xs={12}>
                {renderField(`${condition}`, field)}
              </Grid>
            ))} */}
          </Grid>
        </CardContent>
      </Card>
      {specificationsOpen && (
        <UploadFileDialog
          product_id={props.selectedCategory?.id}
          randomId={props.randomId}
          master_type="category"
          handleUplaod={handleUplaodSpecificationOpen}
          open={specificationsOpen}
          // Alreadyexistingfiles={props.categorySpecFiles}
          updateCategorySpecificationFiles={(newFiles) => {
            setCategorySpecificationFiles(newFiles);
          }}
          handleClose={() => {
            setSpecificationsOpen(false);
          }}
        />
      )}
      {termsAndConditionsPopUp && (
        <TermsAndConditions
          category_id={this.props.selectedCategory?.id}
          open={termsAndConditionsPopUp}
          termsAndConditionsData={this.state.termsAndConditionsData}
          handleClose={() => {
            setTermsAndConditionsPopUp(false);
          }}
        />
      )}
      {dynamicFileUploadOpen && (
        <UploadDynamicView
          sessionID={props.sessionId}
          Loading={Loading}
          uploadedFiles={offlineAttachment?.data}
          open={dynamicFileUploadOpen}
          handleClose={handleUploadClose}
        />
      )}
        <div>
        <Dialog
          open={IframeVisible}
          onClose={() => {
            setIframeVisible(false)
          }}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
          maxWidth="false"
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              height: "100%",
            },
          }}
          fullScreen
        >

          {/* Iframe content */}
          <iframe
            id="iframePost"
            title="Your Iframe Title"
            src={`${parentHost}/cgi-bin/vendx/iframe_pp.cgi?action=80&TLGUID=${queryParams.get("TLGUID")}&sessionID=${queryParams.get("q")}`}
            width="100%"
            height="100%"
            style={{ height: "100%", border: "none" }}
          ></iframe>
        </Dialog>
      </div>

    </>
  );
};

export default QuotationDetailsSectionView;
